import React from "react";
import ReactDOM from "react-dom/client";
import "./Assets/css/style.css";
import "./Assets/css/responsive.css";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "../src/Assets/font/stylesheet.css";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { store } from "./redux/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

     <Provider store={store}>
      <Toaster position="top-right"
      reverseOrder={false} />
    <App />
    </Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
