import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import { deleteBrand, getBrand, getBrandById, updateBrand } from "../actions/adminActions";

const initialState = {
    brands: [],
    totalBrands: null,
    brandDetails: null,
};

export const brandSlice = createSlice({
  name: "brandSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      
      .addCase(getBrand.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.brands = payload.data.service;
          state.totalBrands = payload.data.totalServices;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getBrandById.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.brandDetails = payload.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(deleteBrand.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          console.log(payload,"payload");
          state.brands = state.brands?.filter((item)=> item?._id != payload?.data?._id);
         // toast.success(payload?.message);
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      
  },
});

export default brandSlice.reducer;
