import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Chart from "react-apexcharts";
import ReactApexChart from "react-apexcharts";
import Users from "../Components/Layout/elements/ChartUsers";
import Orders from "../Components/Layout/elements/ChartOrders";
import { dashboard, getAnalyticsUsersData } from "../redux/actions/adminActions";
import { useDispatch, useSelector } from "react-redux";

export default function Dashboard() {
  const dispatch = useDispatch();
  const dash_data = useSelector((state) => state.dashboardSlice.dashboard);
  const analyticsUsersData = useSelector((state) => state.adminSlice.analyticsUsersData);



  useEffect(() => {
    dispatch(dashboard());
    dispatch(getAnalyticsUsersData());
  }, []);

  console.log(dash_data, "dashboard");
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Dashboard</h2>
        </div>
      </div>
      <Container fluid>
        <div className="dashboard-items">
          <Row>
            <Col xxl={12} xl={12} lg={12}>
              <div className="dashbox-inner-wrap">
                <Row>
                  <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes">
                      <h6>Total Salons</h6>
                      <h4>{dash_data?.totalSalons}</h4>
                      {/* <p>Total Average users per day 6774</p> */}
                    </div>
                  </Col>
                  <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes">
                      <h6>Total Site Visits (Past 7 Days)</h6>
                      <h4>{analyticsUsersData?.getSiteVisitsPast7Days || 0}</h4>
                      {/* <p>Total Average users per day 5676</p> */}
                    </div>
                  </Col>
                  <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes">
                      <h6>New Salon Registrations (Past 7 days)</h6>
                      <h4>{dash_data?.totalRegisterPast7days}</h4>
                      {/* <p>Average users per day 589875 </p> */}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            {/* <Col xxl={3} xl={4} lg={3}>
                  <div className="dashbox-side">
                    <h2>ACCOUNTS OVERVIEW</h2>
                    <div className="dash-inner-boxes">
                      <h6>ACCOUNT CURRENT </h6>
                      <h4>£2,202,330.00</h4>
                      <p>10% increase from last month</p>
                    </div>
                  </div>
                </Col> */}
          </Row>

          <Row className="dash-bottom mb-4">
            <Col xxl={6} xl={6} lg={6} className="mb-4">
              <div className="dash-graph">
                <h6>Salon Subscriptions</h6>
                <Users dash_data={dash_data} />
              </div>
            </Col>
            {/* <Col xxl={4} xl={4} lg={4}>
                  <div className="dashbox-side">
                    <h2>MEMBERS </h2>
                    <div className="dash-inner-boxes">
                      <h6>TOTAL MEMBERS </h6>
                      <h4>10,292</h4>
                      <p>10% increase from last month</p>
                    </div>
                    <div className="dash-inner-boxes">
                      <h6>TOTAL CLUBS </h6>
                      <h4>761</h4>
                      <p>10% increase from last month</p>
                    </div>
                  </div>
                </Col> */}
            <Col xxl={6} xl={6} lg={6}>
              <div className="dash-graph ">
                <h6>Star Reviews</h6>
                <Orders dash_data={dash_data} />
              </div>
            </Col>
            {
              !analyticsUsersData && <div className="loader-main">
              <div class="loader"></div>
              </div>
            }
          </Row>
        </div>
      </Container>
    </Layout>
  );
}
