import React from "react";
import ReactPaginate from "react-paginate";

const Pagination = ({ totalStuff, limit, setPage,page }) => {
  return (
    <div className="pagination_new">
      <ReactPaginate
        activeClassName="active"
        nextLabel="Next"
        onPageChange={(e) => setPage(e?.selected + 1)}
        pageRangeDisplayed={1}
        pageCount={totalStuff / limit}
        previousLabel="Prev"
        renderOnZeroPageCount={null}
        className="pagination"
        forcePage={page ? page-1 : 0}
      />
    </div>
  );
}

export default Pagination;
