import { createAsyncThunk } from "@reduxjs/toolkit";
import adminApi from "../../services/adminApi";
import { toast } from "react-hot-toast";
import Cookies from "universal-cookie";
import moment from "moment/moment";

const cookies = new Cookies();

const login = createAsyncThunk("login", async ({ loginObj }) => {
  const { data } = await adminApi.post("/auth/login", loginObj);
  if (data?.success) {
    console.log(data?.data);
    sessionStorage.setItem("token", data?.data?.token);
    sessionStorage.setItem("full_name", data?.data?.full_name);
    sessionStorage.setItem("id", data?.data?._id);
    // if (keepMeLoggedIn) {
    //   cookies.set("token", data?.data?.token, {
    //     path: "/",
    //     expires: moment().add(7, "days").toDate(),
    //   });
    // }
    toast.success(data?.message);
    window.location.href = "/Dashboard";
  } else if (!data?.success) {
    toast.error(data?.message);
  }
});

const changePassword = createAsyncThunk("login", async ({ passObj }) => {
  const { data } = await adminApi.post("/auth/changePassAdmin", passObj);
  if (data?.success) {
    toast.success(data?.message);
    window.location.href = "/admin/dashboard";
  } else if (!data?.success) {
    toast.error(data?.message);
  }
});

export { login, changePassword };
