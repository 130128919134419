import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);



export default function Orders({dash_data}) {
  const data = {
    labels: [
      `1Star ${dash_data?.percentageOneStarRating}%`,
       `2Star ${dash_data?.percentageTwoStarRating}%`,
       `3Star ${dash_data?.percentageThreeStarRating}%`,
       `4Star ${dash_data?.percentageFourStarRating}%`,
       `5Star ${dash_data?.percentageFiveStarRating}%`,
      ],
    datasets: [
      {
        label: "# of Votes",
        data: [
          dash_data?.totalOneStarRating,
          dash_data?.totalTwoStarRating,
          dash_data?.totalThreeStarRating,
          dash_data?.totalFourStarRating,
          dash_data?.totalFiveStarRating,
        ],
        backgroundColor: ["#979797", "#B9B5AB", "#F0EFEC","#F0EFEC","#F0EFEC"],
  
        borderWidth: 1,
      },
    ],
  };
  return <Doughnut className="gender-chart" data={data} />;
}
