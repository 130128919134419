import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Field, Formik } from "formik";
import { permissionObj } from "../utils/statics";
import { createAdmin, getAdminById, updateAdmin } from "../redux/actions/adminActions";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
export default function CreateAdmin() {
  let query = useQuery();
  let queryId = query.get("id");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [adminDetail, setAdminDetail] = useState();


  useEffect(() => {
    if (queryId) {
      dispatch(getAdminById(queryId)).then((data) => {
        setAdminDetail(data?.payload?.data);
      });
    }
  }, [queryId])
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Create Administrator</h2>
          </div>
          <div></div>
        </div>
        <hr />
      </div>


      <Formik
        enableReinitialize={true}
        initialValues={{
          full_name: adminDetail?.full_name || "",
          email: adminDetail?.email || "",
          phone_number: adminDetail?.phone_number || "",
          role: adminDetail?.role || "",
          access_permissions: adminDetail?.access_permissions || {
            view_user_sensitive_data: 0,
            edit_data: 0,
            dashboard: 0,
            customer_mngmt: 0,
            vendor_mngmt: 0,
            business_mngmt: 0,
            verification: 0,
            support: 0,
            notification: 0,
            manage_admins: 0,
          }
        }}
        validate={(values) => {
          const errors = {};

          if (!values.full_name) {
            errors.full_name = "Required*";
          }
          if (!values.email) {
            errors.email = "Required*";
          }
          if (!values.phone_number) {
            errors.phone_number = "Required*";
          }
          if (!values.role) {
            errors.role = "Required*";
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          console.log(values, "values dfbshfbdshbfsdb");

          if (adminDetail) {
            values.id = adminDetail?._id
            dispatch(updateAdmin(values)).then((data) => {
              if (data?.payload?.success) {
                resetForm();
                toast.success(data?.payload?.message);
                navigate(
                  `/Administrators`
                );
                //window.location.reload()
              } else {
                toast.error(data?.payload.message)
              }
            });
          } else {

            dispatch(createAdmin(values)).then((data) => {
              if (data?.payload?.success) {
                resetForm();
                toast.success(data?.payload?.message);
                navigate(
                  `/Administrators`
                );
                //window.location.reload()
              } else {
                toast.error(data?.payload.message)
              }
            });
          }

        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form
            onSubmit={(e) => {
              // setValidateValue(true);
              handleSubmit(e);
            }}
          >
            <Container fluid>
              <div className="product-cmn-tab">
                <Row>
                  <Col lg={4}>
                    <div className="product-tab-left">
                      <Link to="/Administrators">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="40"
                          height="42"
                          viewBox="0 0 40 42"
                          fill="none"
                        >
                          <path
                            d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                            fill="#40413A"
                          />
                        </svg>{" "}
                        Back to previous page
                      </Link>
                    </div>
                  </Col>
                  <Col
                    lg={8}
                    className="d-flex justify-content-end align-items-center"
                  >
                    <div className="cmn-btn">
                      <button type="submit">{adminDetail ? 'Update' : 'Create'} </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
            <Container fluid className="px-4">
              <Row className="justify-content-center mt-4">
                <Col lg={6}>
                  <div className="customer-form-new">
                    <div className="customer-form-inner">
                      <Row>
                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Admin Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Type Here"
                              className="form-control"
                              name="full_name"
                              onChange={handleChange}
                              value={values.full_name}
                            />
                            <span className="formik-errors">
                              {errors.full_name && touched.full_name && errors.full_name}
                            </span>
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Type Here"
                              name="email"
                              onChange={handleChange}
                              value={values.email}
                            />
                            <span className="formik-errors">
                              {errors.email && touched.email && errors.email}
                            </span>
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Mobile Phone</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="+44"
                              name="phone_number"
                              onChange={handleChange}
                              value={values.phone_number}
                            />
                            <span className="formik-errors">
                              {errors.phone_number && touched.phone_number && errors.phone_number}
                            </span>
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          {/* <Form.Group className="mb-3">
                            <Form.Label>Role</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Type Here"
                              name="role"
                              onChange={handleChange}
                              value={values.admin_role}
                            />
                            <span className="formik-errors">
                              {errors.admin_role && touched.admin_role && errors.admin_role}
                            </span>
                          </Form.Group> */}
                          <Form.Select
                          className="mb-3"
                            name="role"
                            onChange={handleChange}
                            aria-label="Default select example"
                            value={values.role}
                          >
                            <option>Select Role</option>
                            <option value="admin">Admin</option>
                            <option value="superadmin">SuperAdmin</option>
                          </Form.Select>
                          <span className="formik-errors">
                            {errors.role && touched.role && errors.role}
                          </span>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
                <Col lg={6} className="">
                  <div className="customer-form-new">
                    <h2>ACCESS & PERMISSIONS</h2>
                    <hr className="cmn-border" />
                    <div className="access-permission-main mt-4">
                      <Row>
                        <Col lg={12}>
                          {
                            permissionObj?.map((item) => {
                              return (
                                <>
                                  <div className="access-select-box">
                                    <p>{item?.label}</p>
                                    <div className="d-flex inner">
                                      <Field
                                        className="form-check-input"
                                        type="checkbox"
                                        name={`access_permissions.${item.key}`}
                                        id={item.key}
                                        value={1}
                                        checked={values.access_permissions[item.key] === 1}
                                        onChange={(e) => {
                                          const isChecked = e.target.checked;
                                          setFieldValue(`access_permissions.${item.key}`, isChecked ? 1 : 0);
                                        }} />
                                    </div>
                                  </div>
                                </>
                              )
                            })
                          }

                          {/* <div className="access-select-box">
                            <p>Edit Data</p>
                            <div className="d-flex inner">
                              <Form>
                                {["checkbox"].map((type) => (
                                  <div key={`inline-${type}`}>
                                    <Form.Check
                                      inline
                                      name="group1"
                                      type={type}
                                      id={`inline-${type}-1`}
                                    />
                                  </div>
                                ))}
                              </Form>
                            </div>
                          </div> */}
                          {/* <div className="access-select-box">
                            <p>Dashboard</p>
                            <div className="d-flex inner">
                              <Form>
                                {["checkbox"].map((type) => (
                                  <div key={`inline-${type}`}>
                                    <Form.Check
                                      inline
                                      name="group1"
                                      type={type}
                                      id={`inline-${type}-1`}
                                    />
                                  </div>
                                ))}
                              </Form>
                            </div>
                          </div> */}

                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </form>
        )}
      </Formik>
    </Layout>
  );
}
