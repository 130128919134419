import React, { useState } from "react";
import Layout from "../Components/Layout/Layout";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { changePass } from "../redux/actions/adminActions";
import toast from "react-hot-toast";

export default function ChangePassword() {

  const dispatch = useDispatch();
  const [validateValue, setValidateValue] = useState(false);

  const validate = (values) => {
    const errors = {};
    if (!values.new_password) {
      errors.new_password = "Required";
    } else if (values.new_password != values.confirm_password) {
      errors.confirm_password = "Password Doesn't match";
    }

    return errors;
  };
  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
    validate,
    validateOnChange: validateValue,
    onSubmit: (values) => {
      setValidateValue(true)
      dispatch(changePass({ password: values?.confirm_password, old_password: values?.old_password })).then((data) => {
        if (data?.payload?.success) {
          formik.resetForm();
          toast.success(data?.payload?.message)
        }
      });

    },
  });

  return (
    <>
      <Layout>
        <div className="right-top">
          <div className="heading-top">
            <h2>Change Password</h2>
          </div>
          <hr />
        </div>
        <Container fluid>
          <Row className="justify-content-md-center mt-4">
            <Col lg="7" md="auto">
              <Card className="border-0 p-5 rounded-card customer-form-new">
                <h2>Change Password</h2>

                <Form onSubmit={formik.handleSubmit} className="change-password-form px-5">
                  <Row className="mb-4">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Enter Old Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Type Here"
                        name="old_password"
                        onChange={formik.handleChange}
                        value={formik.values.old_password}
                      />
                      {formik.errors.old_password && (
                        <div className="error_valid">
                          {formik.errors.old_password}
                        </div>
                      )}
                    </Form.Group>
                  </Row>
                  <Row className="mb-4">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Enter New Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Type Here"
                        name="new_password"
                        onChange={formik.handleChange}
                        value={formik.values.new_password}
                      />
                      {formik.errors.new_password && (
                        <div className="error_valid">
                          {formik.errors.new_password}
                        </div>
                      )}
                    </Form.Group>
                  </Row>
                  <Row className="mb-4">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Type Here"
                        name="confirm_password"
                        onChange={formik.handleChange}
                        value={formik.values.confirm_password}
                      />
                      {formik.errors.confirm_password && (
                        <div className="error_valid">
                          {formik.errors.confirm_password}
                        </div>
                      )}
                    </Form.Group>
                  </Row>
                  <Form.Group
                    className="mt-4  mb-5 model-btn text-center"
                    controlId="formGridAddress2"
                  >
                    <div class="cmn-btn">
                      <button type="submit">SUBMIT</button>
                    </div>
                  </Form.Group>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
}
