import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { createBrand, getBrandById, updateBrand } from "../redux/actions/adminActions";
import toast from "react-hot-toast";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function CreateBrand() {
  let query = useQuery();
  let queryId = query.get("id");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { brandDetails } = useSelector((state) => ({
    brandDetails: state.brandSlice.brandDetails
  }));
  const [prevImg, setPrevImg] = useState([]);
  const [brandDetail, setBrandDetail] = useState();

  useEffect(() => {
    if (queryId) {
      dispatch(getBrandById(queryId)).then((data) => {
        setBrandDetail(data?.payload?.data);
      });
    }
  }, [queryId])

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <h2>Create Brand</h2>

          <div>
            <hr />
          </div>
        </div>
      </div>
      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/allbrands">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Container fluid className="height-set">
     
        <Formik
          enableReinitialize={true}
          initialValues={{
            title: brandDetail?.title || '',
            link:  brandDetail?.link || '',
            brand_logo:brandDetail?.logo || '',
          }}
          validate={(values) => {
            const errors = {};
            if (!values.title) {
              errors.title = "Required*";
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            console.log(values, "values");

            const formData = new FormData();
            for (let key in values) {
              formData.append(key, values[key]);
            }

            if (brandDetail) {
              formData.append(`id`, brandDetail?._id);
              dispatch(updateBrand(formData)).then((data) => {
                if (data?.payload?.success) {
                  //toast.success(data?.payload?.message)
                  navigate(`/allbrands`)
                }
              });
            } else {
              dispatch(createBrand(formData)).then((data) => {
                if (data?.payload?.success) {
                  navigate(`/allbrands`)
                }
              });
            }


            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue
          }) => (
            <Form onSubmit={handleSubmit}>
              <Row className="justify-content-center ">
                <Col lg={8}>
                  <div className="customer-form-new mb-4">
                    <div className="customer-form-inner">
                      <Row>
                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Type Here"
                              name="title"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.title}
                            />
                            <span className="formik-errors">
                              {errors.title && touched.title && errors.title}
                            </span>
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Website Link</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Type Here"
                              name="link"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.link}
                            />
                            <span className="formik-errors">
                              {errors.link && touched.link && errors.link}
                            </span>
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          <Form.Group className="mb-3 image-upload-main">
                            <Form.Label>Logo</Form.Label>
                            <div className="input-image-show">
                              <Form.Control
                                type="file"
                                multiple
                                placeholder="Upload Image"
                                onChange={(e) => {
                                  setFieldValue('brand_logo',e.target.files[0]);
                                  setPrevImg(URL.createObjectURL(e.target.files[0]))
                                }}
                              />
                                {
                                  prevImg && <img className="fit_img" src={prevImg} />
                                }
                              <p>Upload Image</p>
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
                <Col lg={8} className="d-flex justify-content-center mt-2">
                  {" "}
                  <div className="cmn-btn">
                    <button type="submit" to="#">Create</button>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  );
}
