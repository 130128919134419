import React, { useState } from 'react';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceArea,
  ResponsiveContainer,
} from 'recharts';

// const initialData = [
//   { name: 1, Users: 20.11, impression: 100 },
//   { name: 2, Users: 2.39, impression: 120 },
//   { name: 3, Users: 1.37, impression: 150 },
//   { name: 4, Users: 1.16, impression: 180 },
//   { name: 5, Users: 2.29, impression: 200 },
//   { name: 6, Users: 3, impression: 499 },
//   { name: 7, Users: 0.53, impression: 50 },
//   { name: 8, Users: 2.52, impression: 100 },
//   { name: 17, Users: 3, impression: 200 },
//   { name: 18, Users: 2, impression: 50 },
//   { name: 19, Users: 3, impression: 100 },
//   { name: 20, Users: 7, impression: 100 },
// ];


const UsersChart = ({initialData}) => {
  const [state, setState] = useState({
    data: initialData,
    left: 'dataMin',
    right: 'dataMax',
    refAreaLeft: '',
    refAreaRight: '',
    top: 'dataMax+1',
    bottom: 'dataMin-1',
    top2: 'dataMax+20',
    bottom2: 'dataMin-20',
    animation: true,
  });

  const zoom = () => {
    let { refAreaLeft, refAreaRight, data } = state;

    if (refAreaLeft === refAreaRight || refAreaRight === '') {
      setState((prevState) => ({
        ...prevState,
        refAreaLeft: '',
        refAreaRight: '',
      }));
      return;
    }

    if (refAreaLeft > refAreaRight) {
      [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];
    }

    const [bottom, top] = getAxisYDomain(refAreaLeft, refAreaRight, 'Users', 1);
    const [bottom2, top2] = getAxisYDomain(refAreaLeft, refAreaRight, 'impression', 50);

    setState((prevState) => ({
      ...prevState,
      refAreaLeft: '',
      refAreaRight: '',
      data: data.slice(),
      left: refAreaLeft,
      right: refAreaRight,
      bottom,
      top,
      bottom2,
      top2,
    }));
  };

  const getAxisYDomain = (from, to, ref, offset) => {
    const refData = initialData.slice(from - 1, to);
    let [bottom, top] = [refData[0][ref], refData[0][ref]];
    refData.forEach((d) => {
      if (d[ref] > top) top = d[ref];
      if (d[ref] < bottom) bottom = d[ref];
    });
    return [(bottom | 0) - offset, (top | 0) + offset];
  };
  

  const zoomOut = () => {
    setState((prevState) => ({
      ...prevState,
      data: initialData.slice(),
      refAreaLeft: '',
      refAreaRight: '',
      left: 'dataMin',
      right: 'dataMax',
      top: 'dataMax+1',
      bottom: 'dataMin',
      top2: 'dataMax+50',
      bottom2: 'dataMin+50',
    }));
  };

  return (
    <div className="highlight-bar-charts" style={{ userSelect: 'none', width: '100%' }}>
      {/* <button type="button" className="btn update" onClick={zoomOut}>
        Zoom Out
      </button> */}

      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          width={800}
          height={400}
          data={state.data}
          onMouseDown={(e) => setState((prevState) => ({ ...prevState, refAreaLeft: e.activeLabel }))}
          onMouseMove={(e) =>
            state.refAreaLeft && setState((prevState) => ({ ...prevState, refAreaRight: e.activeLabel }))
          }
          onMouseUp={zoom}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis allowDataOverflow dataKey="name" domain={[state.left, state.right]} type="number" />
          <YAxis allowDataOverflow domain={[state.bottom, state.top]} type="number" yAxisId="1" />
          <YAxis orientation="right" allowDataOverflow domain={[state.bottom2, state.top2]} type="number" yAxisId="2" />
          <Tooltip />
          <Line yAxisId="1" type="natural" dataKey="Users" stroke="#8884d8" animationDuration={300} />
          
          {state.refAreaLeft && state.refAreaRight ? (
            <ReferenceArea yAxisId="1" x1={state.refAreaLeft} x2={state.refAreaRight} strokeOpacity={0.3} />
          ) : null}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default UsersChart;
