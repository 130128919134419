import axios from "axios";

const adminApi = axios.create({
  baseURL: process.env.REACT_APP_ADMIN_BASE_URL,
  headers: {
    authorization: sessionStorage.getItem("token"),
  },
});

adminApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // if(error?.response?.status == 500 || error?.response?.status == 401){
    //   sessionStorage.clear();
    //   localStorage.clear();
    //   window.location.href = '/'
    // }
    return error.response;
  }
);

export default adminApi;
