import { configureStore } from "@reduxjs/toolkit";
import userMgmtReducer from "./reducers/userMgmtSlice";
import dashboardReducer from "./reducers/dashboardSlice";
import managePropertySlice from "./reducers/managePropertySlice";
import brandSlice from "./reducers/brandSlice";
import blogSlice from "./reducers/blogSlice";
import reviewSlice from "./reducers/reviewSlice";
import adminSlice from "./reducers/adminSlice";
import supportSlice from "./reducers/supportSlice";

export const store = configureStore({
  reducer: {
    userMgmtSlice: userMgmtReducer,
    dashboardSlice: dashboardReducer,
    managePropertySlice: managePropertySlice,
    brandSlice: brandSlice,
    reviewSlice: reviewSlice,
    blogSlice: blogSlice,
    adminSlice: adminSlice,
    supportSlice: supportSlice
  },
});
