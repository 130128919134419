import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { getAllSupport } from "../redux/actions/adminActions";
import moment from "moment";
import Pagination from "../Components/Layout/elements/Pagination";

export default function Support() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const BASE_URL = process.env.REACT_APP_HOST_NAME;
  const { supportArr, totalSupport } = useSelector((state) => ({
    supportArr: state.supportSlice.supportArr,
    totalSupport: state.supportSlice.totalSupport,
  }));

  const [search, setSearch] = useState("");

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);

  useEffect(() => {
    dispatch(
      getAllSupport({
        page: search ? 1 : page,
        limit: limit,
        search: search,
        user_type: 0,
      })
    );
  }, [page, limit, search]);

  console.log(supportArr, totalSupport, "dddddddddddddddddddddddd");
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Support</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={3} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Keyword Search.."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xxl={2} xl={2} lg={3} md={4}>
                {/* <Form.Select aria-label="Default select example">
                  <option>Date range</option>
                  <option value="1">24</option>
                  <option value="2">25</option>
                  <option value="3">26</option>
                </Form.Select> */}
              </Col>
              <Col xxl={2} xl={2} lg={3} md={4}>
                {/* <Form.Select aria-label="Default select example">
                  <option>Category</option>
                  <option value="1">Dummy</option>
                  <option value="2">Dummy</option>
                  <option value="3">Dummy</option>
                </Form.Select> */}
              </Col>
              <Col xxl={2} xl={2} lg={3} md={4}>
                {/* <Form.Select aria-label="Default select example">
                  <option>Assigned to</option>
                  <option value="1">Dummy</option>
                  <option value="2">Dummy</option>
                  <option value="3">Dummy</option>
                </Form.Select> */}
              </Col>

              <Col className="d-flex justify-content-end align-items-center">
                <div className="cmn-btn support">
                  <Link to="#">DOWNLOAD</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                Showing {1 + (page - 1) * limit} -{" "}
                {supportArr
                ?.length + (page - 1) * limit} of {totalSupport} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  onChange={(e) => setLimit(e.target.value)}
                  aria-label="Default select example"
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>Ticket ID</th>
                <th>Date</th>
                <th>Name</th>
                <th>Surname</th>
                <th>Email </th>
                <th>Phone Number</th>
                <th>Description</th>
                {/* <th>Assigned To</th> */}
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {supportArr?.map((item, i) => {
                return (
                  <>
                    <tr>
                      <td>{i + 1}</td>
                      <td>
                        {moment(item?.createdAt).format("DD/MM/YYYY") || "N/A"}
                      </td>
                      <td>
                        <Link to={`/ticketdetail/${item?._id}`}>{item?.full_name}</Link>
                      </td>
                      <td>{item?.surname}</td>
                      <td>{item?.email}</td>
                      <td>{item?.phone_number}</td>

                      <td>{item?.comment}</td>
                      {/* <td>Admin</td> */}
                      <td>{item?.status == 1 ? 'Open' : 'Close'}</td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>
          {supportArr?.length == 0 && (
            <div className="progress-line">Content isn't available yet!</div>
          )}
        </div>
      </Container>

      <Pagination
        class="next-btn-new"
        totalStuff={totalSupport}
        limit={limit}
        setPage={setPage}
      />
    </Layout>
  );
}
