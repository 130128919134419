import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import { deleteReview, getAllReviews, getReviewById } from "../actions/adminActions";

const initialState = {
    reviews: [],
    totalReviews: null,
    reviewDetails: null,
};

export const reviewSlice = createSlice({
  name: "reviewSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      
      .addCase(getAllReviews.fulfilled, (state, { payload }) => {

        if (payload?.success) {
          state.reviews = payload.data.review;
          state.totalReviews = payload.data.totalReviews;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getReviewById.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.reviewDetails = payload.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(deleteReview.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          console.log(payload,"payload");
          state.reviews = state.reviews?.filter((item)=> item?._id != payload?.data?._id);
         // toast.success(payload?.message);
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      
  },
});

export default reviewSlice.reducer;
