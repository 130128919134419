import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { deleteBrand, getBrand } from "../redux/actions/adminActions";
import Pagination from "../Components/Layout/elements/Pagination";

export default function AllBrands() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const BASE_URL = process.env.REACT_APP_HOST_NAME;
  const { brands, totalBrands } = useSelector(
    (state) => ({
      brands: state.brandSlice.brands,
      totalBrands: state.brandSlice.totalBrands,
    })
  );

  const [search, setSearch] = useState("");

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);

  useEffect(() => {
    dispatch(
      getBrand({
        page: search ? 1 : page,
        limit: limit,
        search: search,
        user_type: 0,
      })
    );
  }, [page, limit, search]);

  console.log(brands,totalBrands);
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>All Brands</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                <Form.Control
                    type="search"
                    placeholder="Keyword Search.."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col
                xxl={9}
                xl={9}
                lg={8}
                md={8}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <Link to="/CreateBrand">Create Brand</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">Showing {1 + (page - 1) * limit} -{" "}
                {brands?.length + (page - 1) * limit} of{" "}
                {totalBrands} results</div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  onChange={(e) => setLimit(e.target.value)}
                  aria-label="Default select example"
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Brand Name</th>
                <th>Logo</th>
                <th>Website Link</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              
              {brands?.map((item, i) => {
                return (
                  <tr key={item?._id}>
                    <td>{i + 1 + (page - 1) * limit}</td>
                    <td>{item?.title || "N/A"}</td>
                    <td className="user-img">
                      <img
                        src={
                          item?.brand_logo
                            ? BASE_URL + "/" + item?.brand_logo
                            : require("../Assets/Images/salon-image.png")
                        } />
                      </td>
                    <td>{item?.link || "N/A"}</td>
                   
                      <td> 
                  <Button className="account-btn"
                    onClick={()=>navigate(`/CreateBrand?id=${item?._id}`)}
                  >Edit</Button>{" "}
                  <Button onClick={()=>dispatch(deleteBrand(item?._id))} className="account-btn-delete">Delete</Button>{" "}
                </td>
                    </tr>

                  )
                })
              }
            </tbody>
          </Table>
          <Pagination
            class="next-btn-new"
            totalStuff={totalBrands}
            limit={limit}
            setPage={setPage}
          />
        </div>
      </Container>

   
    </Layout>
  );
}
