import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);



export default function Users({dash_data}) {

  const data = {
    labels: [`Gold ${dash_data?.goldPercentage}%`, `Silver ${dash_data?.silverPercentage}%`, `Bronze ${dash_data?.bronzePercentage}%`],
    datasets: [
      {
        label: "# of Votes",
        data: [dash_data?.totalGoldSubscription, dash_data?.totalSilverSubscription, dash_data?.totalBronzeSubscription],
        backgroundColor: ["#979797", "#B9B5AB", "#F0EFEC"],
  
        borderWidth: 1,
      },
    ],
  };

  return <Doughnut className="gender-chart" data={data} />;
}
