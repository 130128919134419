import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import { deleteBlog, getBlog, getBlogById, updateBlog } from "../actions/adminActions";

const initialState = {
    blogs: [],
    totalBlogs: null,
    blogDetails: null,
    totalBlogCount:0,
    totalPublishCount:0,
    totalDraftCount:0,
};

export const blogSlice = createSlice({
  name: "blogSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      
      .addCase(getBlog.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.blogs = payload.data.service;
          state.totalBlogs = payload.data.totalServices;
          state.totalBlogCount = payload.data.totalBlog;
          state.totalPublishCount = payload.data.totalPublishBlog;
          state.totalDraftCount = payload.data.totalDraftBlog;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getBlogById.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.blogDetails = payload.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(deleteBlog.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          console.log(payload,"payload");
          state.blogs = state.blogs?.filter((item)=> item?._id != payload?.data?._id);
         // toast.success(payload?.message);
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      
  },
});

export default blogSlice.reducer;
