import { createAsyncThunk } from "@reduxjs/toolkit";
import adminApi from "../../services/adminApi";
import { toast } from "react-hot-toast";




const getSupportTicket = createAsyncThunk("getSupportTicket", async (id) => {
  const { data } = await adminApi.get(`/getSupportTicket`);
  return data;
});

const getAllSupport = createAsyncThunk("getAllSupport", async ({ page, limit, search }) => {
  const { data } = await adminApi.get(`/getAllSupport?page=${page}&limit=${limit}&search=${search}`);
  return data;
});

const dashboard = createAsyncThunk("dashboard", async (id) => {
  const { data } = await adminApi.get(`/dashboard`);
  return data;
});


const getAllAdmin = createAsyncThunk("getAllAdmin", async ({ page, limit, search }) => {
  const { data } = await adminApi.get(`/getAllAdmin?page=${page}&limit=${limit}&search=${search}`);
  return data;
});

const getAdminById = createAsyncThunk("getAdminById", async (id) => {
  const { data } = await adminApi.get(`/getAdminById?id=${id}`);
  return data;
});



const updateAdmin = createAsyncThunk(
  "updateAdmin",
  async (formdata) => {
    const { data } = await adminApi.put("/updateAdmin", formdata);
    return data;
  }
);

const deleteAdmin = createAsyncThunk("deleteAdmin", async (id) => {
  const { data } = await adminApi.delete(`/deleteAdmin?id=${id}`);

  data?.success && toast.success(data?.message);
  !data?.success && toast.success(data?.message);

  return data;
});


const createAdmin = createAsyncThunk(
  "createAdmin",
  async (data, thunkAPI) => {
    const response = await adminApi.post("/createAdmin", data);
    return response.data;
  }
);

const changePass = createAsyncThunk(
  "changePass",
  async (data, thunkAPI) => {
    const response = await adminApi.put("/changePass", data);
    return response.data;
  }
);

const createBlog = createAsyncThunk("createBlog", async (obj) => {
  const { data } = await adminApi.post(`/createBlog`, obj);

  data?.success && toast.success(data?.message);
  !data?.success && toast.success(data?.message);

  return data;
});

const getBlog = createAsyncThunk("getBlog", async ({ page, limit, search, status, createdDate, createdBy }) => {
  const { data } = await adminApi.get(`/getBlog?page=${page}&limit=${limit}&search=${search}&status=${status}&createdDate=${createdDate}&createdBy=${createdBy}`);
  return data;
});

const getBlogById = createAsyncThunk("getBlogById", async (id) => {
  const { data } = await adminApi.get(`/getBlogById?id=${id}`);
  return data;
});



const updateBlog = createAsyncThunk(
  "updateBlog",
  async (formdata) => {
    const { data } = await adminApi.put("/updateBlog", formdata);
    return data;
  }
);

const deleteBlog = createAsyncThunk("deleteBlog", async (id) => {
  const { data } = await adminApi.delete(`/deleteBlog?id=${id}`);

  data?.success && toast.success(data?.message);
  !data?.success && toast.success(data?.message);

  return data;
});

const updateReview = createAsyncThunk(
  "updateReview",
  async (formdata) => {
    const { data } = await adminApi.put("/updateReview", formdata);
    return data;
  }
);

const getAllReviews = createAsyncThunk("getAllReviews", async ({ page, limit, search, status }) => {
  const { data } = await adminApi.get(`/getAllReviews?page=${page}&limit=${limit}&search=${search}&status=${status}`);
  return data;
});

const getReviewById = createAsyncThunk("getReviewById", async (id) => {
  const { data } = await adminApi.get(`/getReviewById?id=${id}`);
  return data;
});

const deleteReview = createAsyncThunk("deleteReview", async (id) => {
  const { data } = await adminApi.delete(`/deleteReview?id=${id}`);

  data?.success && toast.success(data?.message);
  !data?.success && toast.success(data?.message);

  return data;
});



const createBrand = createAsyncThunk("createBrand", async (obj) => {
  const { data } = await adminApi.post(`/createBrand`, obj);

  data?.success && toast.success(data?.message);
  !data?.success && toast.success(data?.message);

  return data;
});

const getBrand = createAsyncThunk("getBrand", async ({ page, limit, search }) => {
  const { data } = await adminApi.get(`/getBrand?page=${page}&limit=${limit}&search=${search}`);
  return data;
});

const getBrandById = createAsyncThunk("getBrandById", async (id) => {
  const { data } = await adminApi.get(`/getBrandById?id=${id}`);
  return data;
});



const updateBrand = createAsyncThunk(
  "updateBrand",
  async (formdata) => {
    const { data } = await adminApi.put("/updateBrand", formdata);
    return data;
  }
);

const deleteBrand = createAsyncThunk("deleteBrand", async (id) => {
  const { data } = await adminApi.delete(`/deleteBrand?id=${id}`);

  data?.success && toast.success(data?.message);
  !data?.success && toast.success(data?.message);

  return data;
});


const deleteSalonAccount = createAsyncThunk("deleteSalonAccount", async (id) => {
  const { data } = await adminApi.delete(`/deleteSalonAccount?id=${id}`);

  data?.success && toast.success(data?.message);
  !data?.success && toast.success(data?.message);

  return data;
});

const deactivateSalonAccount = createAsyncThunk("deactivateSalonAccount", async (obj) => {
  const { data } = await adminApi.post(`/deactivateSalonAccount`, obj);

  data?.success && toast.success(data?.message);
  !data?.success && toast.success(data?.message);

  return data;
});



const getAllUsers = createAsyncThunk(
  "getAllUsers",
  async ({ page, limit, search }) => {
    const { data } = await adminApi.get(
      `/getAllUsers?page=${page}&limit=${limit}&search=${search}`
    );
    return data;
  }
);



const updatePassword = createAsyncThunk("updatePassword", async (id) => {
  const { data } = await adminApi.get(`/updatePassword?id=${id}`);

  data?.success && toast.success(data?.message);
  !data?.success && toast.success(data?.message);

  return data;
});

const userEditProfile = createAsyncThunk(
  "userEditProfile",
  async (signupObj) => {
    const { data } = await adminApi.post("/editProfile", signupObj);
    if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);



//******************OPENING HOURS******************************** *//

const createOpeningHours = createAsyncThunk(
  "createOpeningHours",
  async (data, thunkAPI) => {
    const response = await adminApi.post("/createOpeningHours", data);
    return response.data;
  }
);


const updateOpeningHours = createAsyncThunk(
  "updateOpeningHours",
  async (data, thunkAPI) => {
    const response = await adminApi.put("/updateOpeningHours", data);
    return response.data;
  }
);

const fetchOpeningHours = createAsyncThunk(
  "fetchOpeningHours",
  async (id, thunkAPI) => {
    const response = await adminApi.get(`/fetchOpeningHours?id=${id}`);
    return response.data;
  }
);

export const addAdminNotes = createAsyncThunk(
  "addAdminNotes",
  async (data) => {
    const response = await adminApi.post("/addNotes", data);
    return response.data;
  }
);

//********************************END********************************* *//


const getAllSalon = createAsyncThunk(
  "getAllSalon",
  async ({ page, limit, search, user_type, mailing_pref, subscription, ban_status, sortInsurance, satusFilter }) => {
    const { data } = await adminApi.get(
      `/getAllSalon?page=${page}&limit=${limit}&search=${search}&user_type=${user_type}&mailing_pref=${mailing_pref}&subscription=${subscription}&ban_status=${ban_status}&sortInsurance=${sortInsurance}&statusFilter=${satusFilter}`
    );
    return data;
  }
);

const getAllSalonCSVExport = createAsyncThunk(
  "getAllSalonCSVExport",
  async ({ page, limit, search, user_type, mailing_pref, subscription, ban_status, satusFilter, sortInsurance }) => {
    const { data } = await adminApi.get(
      `/getAllSalonCSVExport?page=${page}&limit=${limit}&search=${search}&user_type=${user_type}&mailing_pref=${mailing_pref}&subscription=${subscription}&ban_status=${ban_status}&sortInsurance=${sortInsurance}&statusFilter=${satusFilter}`
    );
    return data;
  }
);

const getUserDetails = createAsyncThunk("getUserDetails", async (id) => {
  const { data } = await adminApi.get(`/getSalonById/?user_id=${id}`);
  return data;
});

const addProperty = createAsyncThunk("addProperty", async (notObj) => {
  const { data } = await adminApi.post("/createProperties", notObj);
  return data;
});

const imagesaveAction = createAsyncThunk("imagesaveAction", async (notObj) => {
  const { data } = await adminApi.post("/imagesave", notObj);
  return data;
});



const tempBannedAccount = createAsyncThunk(
  "tempBannedAccount",
  async (obj) => {
    const { data } = await adminApi.put("/tempBannedAccount", obj);
    return data;
  }
);
const tempBannedAccountCustomer = createAsyncThunk(
  "tempBannedAccountCustomer",
  async (obj) => {
    const { data } = await adminApi.put("/tempBannedAccount", obj);
    return data;
  }
);



const editUserDetails = createAsyncThunk(
  "editUserDetails",
  async (formdata) => {
    const { data } = await adminApi.put("/editUserDetails", formdata);
    return data;
  }
);

const getAllManageProperties = createAsyncThunk(
  "getAllManageProperties",
  async ({ page, limit, search }) => {
    const { data } = await adminApi.get(
      `/getPropertyList?page=${page}&limit=${limit}&search=${search}`
    );
    return data;
  }
);

const getProperty = createAsyncThunk("getProperty", async (id) => {
  const { data } = await adminApi.get(`/getProperty?id=${id}`);
  return data;
});



const updateProperty = createAsyncThunk(
  "updateProperty",
  async (formdata) => {
    const { data } = await adminApi.post("/updateProperty", formdata);
    return data;
  }
);
const deleteProperty = createAsyncThunk("deleteProperty", async (id) => {
  const { data } = await adminApi.delete(`/deleteProperty?id=${id}`);

  data?.success && toast.success(data?.message);
  !data?.success && toast.success(data?.message);

  return data;
});

const createService = createAsyncThunk("createService", async (obj) => {
  const { data } = await adminApi.post(`/createService`, obj);

  data?.success && toast.success(data?.message);
  !data?.success && toast.success(data?.message);

  return data;
});

const getServiceList = createAsyncThunk(
  "getServiceList",
  async ({ page, limit, search }) => {
    const { data } = await adminApi.get(
      `/getAllSalon?page=${page}&limit=${limit}&search=${search}`
    );
    return data;
  }
);


const changeUserPass = createAsyncThunk("changeUserPass", async (passObj) => {
  const { data } = await adminApi.put("/changeUserPass", passObj);

  data?.success && toast.success(data?.message);
  !data?.success && toast.success(data?.message);

  return data;
});


const getService = createAsyncThunk("getService", async (id) => {
  const { data } = await adminApi.get(`/getService?id=${id}`);
  return data;
});



const updateService = createAsyncThunk(
  "updateService",
  async (formdata) => {
    const { data } = await adminApi.put("/updateService", formdata);
    return data;
  }
);

const deleteService = createAsyncThunk("deleteService", async (id) => {
  const { data } = await adminApi.delete(`/deleteService?id=${id}`);

  data?.success && toast.success(data?.message);
  !data?.success && toast.success(data?.message);

  return data;
});


const getStatics = createAsyncThunk("getStatics", async () => {
  const { data } = await adminApi.get("/getStatics");
  return data;
});

const getAllProfileUpdates = createAsyncThunk("getAllProfileUpdates", async ({ page, limit, search }) => {
  const { data } = await adminApi.get(`/getAllProfileUpdates?page=${page}&limit=${limit}&search=${search}`);
  return data;
});
const profileUpdates = createAsyncThunk(
  "profileUpdates",
  async (formdata) => {
    const { data } = await adminApi.post("/profileUpdates", formdata);
    data?.success && toast.success(data?.message);
    !data?.success && toast.success(data?.message);

    return data;
  }
);

const getSupportById = createAsyncThunk("getSupportById", async (id) => {
  const { data } = await adminApi.get(`/getSupportById?id=${id}`);
  return data;
});

// addSupportNotes

const addSupportNotes = createAsyncThunk(
  "addSupportNotes",
  async (data) => {
    const response = await adminApi.post("/addSupportNotes", data);
    return response.data;
  }
);

const getAnalyticsData = createAsyncThunk("getAnalyticsData", async () => {
  const { data } = await adminApi.get("/getAnalyticsData");
  return data;
});

const getAnalyticsgraphData = createAsyncThunk("getAnalyticsgraphData", async () => {
  const { data } = await adminApi.get("/getAnalyticsgraphData");
  return data;
});

const getEventCounts = createAsyncThunk("getEventCounts", async () => {
  const { data } = await adminApi.get("/getEventCounts");
  return data;
});

const getAnalyticsUsersData = createAsyncThunk("getAnalyticsUsersData", async () => {
  const { data } = await adminApi.get("/getAnalyticsUsersData");
  return data;
});

const uploadImage = createAsyncThunk("uploadImage", async (dataf) => {
  const { data } = await adminApi.post("/uploadImage", dataf);
  return data;
});

export {
  getAllSalonCSVExport,
  uploadImage,
  getAllAdmin,
  getAdminById,
  updateAdmin,
  deleteAdmin,
  createAdmin,
  createBlog,
  getBlog,
  getBlogById,
  updateBlog,
  deleteBlog,
  updateReview,
  deleteReview,
  getReviewById,
  getAllReviews,
  createBrand,
  getBrand,
  getBrandById,
  updateBrand,
  deleteBrand,
  getAllSalon,
  getUserDetails,
  deleteSalonAccount,
  deactivateSalonAccount,
  dashboard,
  changePass,

  changeUserPass,
  editUserDetails,

  getAllUsers,
  addProperty,
  getAllManageProperties,
  getProperty,
  updateProperty,
  deleteProperty,
  createService,
  getServiceList,
  getService,
  updateService,
  deleteService,
  tempBannedAccount,
  tempBannedAccountCustomer,
  imagesaveAction,
  getAllSupport,
  getSupportTicket,
  updatePassword,
  userEditProfile,

  getStatics,
  getAllProfileUpdates,
  profileUpdates,
  createOpeningHours,
  updateOpeningHours,
  fetchOpeningHours,
  getSupportById,
  addSupportNotes,
  getAnalyticsData,
  getAnalyticsgraphData,
  getEventCounts,
  getAnalyticsUsersData,
};
