import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import { Container, Dropdown, Row, Stack } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FiUnlock } from "react-icons/fi";
import { FiLogOut } from "react-icons/fi";
import Profile from "../../Assets/Images/Profile-icon.png";
import { useDispatch, useSelector } from "react-redux";
import { getSupportTicket } from "../../redux/actions/adminActions";
import useAuthToken from "../../Hooks/useAuthToken";
const Layout = ({ children }) => {
  const tokenData = useAuthToken();
  const dispatch = useDispatch()
  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const {
    adminDetails, supportTicket
  } = useSelector((state) => ({
    adminDetails: state.adminSlice.adminDetails,
    supportTicket: state.supportSlice.supportTicket,
  }));

  useEffect(()=>{
    dispatch(getSupportTicket())
  },[])
  console.log(supportTicket,"adminDetails");
  return (
    <div>
      <div className="main-wrap">
        <div className={show ? "left-side hide" : "left-side "}>
          <Sidebar
            isActive={isActive}
            setIsActive={setIsActive}
            show={show}
            setShow={setShow}
            adminDetails={tokenData}
            supportTicket={supportTicket}
          />
        </div>
        <div className={show ? "right-side-cmn hide" : "right-side-cmn "}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
