import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllSalon } from "../redux/actions/adminActions";
import Pagination from "../Components/Layout/elements/Pagination";
import DownloadCsv from "../Components/Layout/elements/DownloadCsv";

export default function MailingList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const BASE_URL = process.env.REACT_APP_HOST_NAME;
  const { users, totalUsers } = useSelector((state) => ({
    users: state.userMgmtSlice.users,
    totalUsers: state.userMgmtSlice.totalUsers,
  }));

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);

  useEffect(() => {
    dispatch(
      getAllSalon({
        page: search ? 1 : page,
        limit: limit,
        search: search,
        user_type: 1,
        mailing_pref: 1
      })
    );
  }, [page, limit, search]);

  console.log(users, totalUsers, "users, totalUsers");

  const data = users?.map((item, i) => {
    return {
      "S.No.": i + 1,
      "Email": item?.email,
    }
  });

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Mailing List</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Keyword Search.."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col
                xxl={9}
                xl={9}
                lg={8}
                md={8}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn ">
                  {/* <Link to="/createmail" className="mx-2">
                    Create Mail
                  </Link> */}
                  <DownloadCsv data={data} />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
            <div className="show-results">
                Showing {1 + (page - 1) * limit} -{" "}
                {users?.length + (page - 1) * limit} of {totalUsers} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  onChange={(e) => setLimit(e.target.value)}
                  aria-label="Default select example"
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Salon Name</th>
                <th>Client Name</th>
                <th>Email</th>
                
              </tr>
            </thead>
            <tbody>
            {users?.map((item, i) => {
                return (
                  
                    <tr>
                      <td>{i + 1}</td>
                      <td>{item?.business_name}</td>
                      <td>{item?.full_name}</td>
                      <td>{item?.email}</td>
                    </tr>
                  
                )
              })}
             

              
            </tbody>
          </Table>
          {
            users?.length == 0 && <div className="progress-line">No data available yet!</div>
          }
          
        </div>
      </Container>

      <Pagination
            class="next-btn-new"
            totalStuff={totalUsers}
            limit={limit}
            setPage={setPage}
          />
    </Layout>
  );
}
