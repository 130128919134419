import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Chart from "react-apexcharts";
import UsersChart from "../Components/Layout/elements/UsersChart";
import ReactApexCharts from "react-apexcharts";
import UserActivity from "../Components/Layout/elements/UserActivity";
import { useDispatch, useSelector } from "react-redux";
import { getAnalyticsData, getAnalyticsUsersData, getAnalyticsgraphData, getEventCounts } from "../redux/actions/adminActions";

export default function Analytics() {
  const dispatch = useDispatch();
  const {
    graphAnlyticsData, eventCounts, analyticsUsersData, userGraphActiveData
  } = useSelector((state) => ({
    graphAnlyticsData: state.adminSlice.graphAnlyticsData,
    eventCounts: state.adminSlice.eventCounts,
    analyticsUsersData: state.adminSlice.analyticsUsersData,
    userGraphActiveData: state.adminSlice.userGraphActiveData
  }));

  const chartOptions = {
    // Define your chart options here
    chart: {
      height: 385,
      type: "bar",
      backgroundColor: ["#979797"],
    },
    tooltip: {
      enabled: false,
    },
    labels: {
      show: false,
    },

    colors: ["#B9B5AB"],
    series: [
      {
        name: "Series 1",
        data: [0, 0, 0, 0, 0],
      },
    ],
    xaxis: {
      categories: ["Jan", "Feb", "March", "April", "May"],
    },
  };

  useEffect(() => {
    dispatch(getAnalyticsData());
    dispatch(getAnalyticsUsersData());
    dispatch(getEventCounts());
    dispatch(getAnalyticsgraphData());
  }, []);


  const newData = graphAnlyticsData?.map(item => {
    const dateObj = new Date(item.date);
    const dayOfMonth = dateObj.getDate();
    return {
      date: item?.date,
      name: dayOfMonth,
      Users: item?.screenPageViews
    };
  });


  console.log(graphAnlyticsData, "dddddddddddddd");
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Analytics</h2>
        </div>
      </div>
      <Container fluid>
        <div className="dashboard-items">
          <Row className="dash-bottom ">
            <Col xxl={6} xl={6} lg={6} className="mb-4">
              <div className="dash-graph h-auto ">
                <Row>
                  <Col lg={6}>
                    <div className="inner-user mb-0">
                      <h6>Users </h6>
                      <h4>{analyticsUsersData?.totalActiveUsers || 0}</h4>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="inner-user mb-0">
                      <h6>New Users</h6>
                      <h4>{analyticsUsersData?.newUsers || 0}</h4>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="dash-graph h-auto">
                <h6>Users</h6>
                <Row>
                  {
                    newData?.length > 0 && <UsersChart initialData={newData} />
                  }

                </Row>
              </div>
            </Col>
            <Col xxl={6} xl={6} lg={6} className="mb-4">
              <div className="dash-graph h-auto ">
                <Row>
                  <Col lg={4}>
                    <div className="inner-user mb-0 px-3">
                      <h6>30 days ago </h6>
                      <h4>{analyticsUsersData?.totalActiveUsers || 0}</h4>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="inner-user mb-0 px-3">
                      <h6>7 days ago </h6>
                      <h4>{analyticsUsersData?.totalWeeklyUsers || 0}</h4>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="inner-user mb-0 px-3">
                      <h6>1 day ago </h6>
                      <h4>{analyticsUsersData?.firstDayUsers || 0}</h4>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="dash-graph h-auto">
                <h6>Users Activity Over Time</h6>
                {userGraphActiveData && <UserActivity initialData={userGraphActiveData} />}
              </div>
            </Col>
            <Col xxl={6} xl={6} lg={6}>
              <div className="dash-graph h-auto mt-2">
                <Row>
                  <Col lg={6}>
                    <h6>Event Name</h6>
                  </Col>
                  <Col lg={6}>
                    <h6>Event Count</h6>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Link to="#" className="event-link">
                      Page View
                    </Link>
                  </Col>
                  <Col lg={6}>
                    <p>{eventCounts?.page_view || 0}</p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Link to="#" className="event-link">
                      Session Start
                    </Link>
                  </Col>
                  <Col lg={6}>
                    <p>{eventCounts?.session_start || 0}</p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Link to="#" className="event-link">
                      Scroll
                    </Link>
                  </Col>
                  <Col lg={6}>
                    <p>{eventCounts?.scroll || 0}</p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Link to="#" className="event-link">
                      User Engagement
                    </Link>
                  </Col>
                  <Col lg={6}>
                    <p>{eventCounts?.user_engagement || 0}</p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Link to="#" className="event-link">
                      Form Start
                    </Link>
                  </Col>
                  <Col lg={6}>
                    <p>{eventCounts?.form_start || 0}</p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Link to="#" className="event-link">
                      First Visit
                    </Link>
                  </Col>
                  <Col lg={6}>
                    <p>{eventCounts?.first_visit || 0}</p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Link to="#" className="event-link">
                      Click
                    </Link>
                  </Col>
                  <Col lg={6}>
                    <p>{eventCounts?.click || 0}</p>
                  </Col>
                </Row>
              </div>
            </Col>
            {
              graphAnlyticsData.length == 0 && !analyticsUsersData && <div className="loader-main">
                <div class="loader"></div>
              </div>
            }

            {/* <Col xxl={12}>
              <div className="google-analytics">
                <p>Google Analytics: Yet to be decided</p>
              </div>
            </Col> */}
          </Row>
        </div>
      </Container>
    </Layout>
  );
}
