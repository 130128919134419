import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { getAllSalon, getAllSalonCSVExport } from "../redux/actions/adminActions";
import Pagination from "../Components/Layout/elements/Pagination";
import moment from "moment";
import { setYourProperty } from "../redux/reducers/adminSlice";
import DownloadCsv from "../Components/Layout/elements/DownloadCsv";

export default function AllSalons() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const BASE_URL = process.env.REACT_APP_HOST_NAME;
  const { users, totalUsers, yourProperty } = useSelector((state) => ({
    users: state.userMgmtSlice.users,
    totalUsers: state.userMgmtSlice.totalUsers,
    yourProperty: state.adminSlice.yourProperty,
  }));

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(yourProperty || 1);
  const [limit, setLimit] = useState(5);
  const [subFilter, setSubFilter] = useState();
  const [satusFilter, setStatusFilter] = useState();
  const [sortInsurance, setSortInsurance] = useState();

  useEffect(() => {
    dispatch(
      getAllSalon({
        page: search ? 1 : page,
        limit: limit,
        search: search,
        user_type: 1,
        subscription: subFilter,
        sortInsurance: sortInsurance,
        satusFilter: satusFilter
      })
    );
  }, [page, limit, search, subFilter, sortInsurance, satusFilter]);





  const updatePage = (page) => {
    setPage(page)
    dispatch(setYourProperty(page));
  };

  const data = users?.map((item, i) => {
    return {
      "S.No.": i + 1,
      'Business Name': item?.business_name,
      Email: item?.email,
      "Insurance Expiry": item?.insurance_expiry,
      Location: item?.city || "-",
      'Subscription Level': item?.subscriptionId == null && item?.subscription ? "Expired"
        : item?.subscription == 1
          ? "Bronze"
          : item?.subscription == 2
            ? "Silver"
            : item?.subscription == 3
              ? "Gold"
              : "N/A",
      Featured: item?.featured_salon == 1 ? 'Yes' : 'No',
      'Last Active': item?.lastLogin && moment(item?.lastLogin).format("DD/MM/YYYY, h:mm") ||
        "N/A",
      Status: item?.status == 0 ? "Temporary Banned" : item?.userExistInSite ? "Published" : "Hidden"
    };
  });

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2 >All Salons </h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Keyword Search.."
                    onChange={(e) => {
                      setPage(1)
                      setSearch(e.target.value);
                      setSubFilter();
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Select
                  onChange={(e) => {
                    setPage(1)
                    setSubFilter(e.target.value);
                    //setSearch();
                  }}
                  aria-label="Default select example"
                >
                  <option value="">Subscription</option>
                  <option value="3">Gold</option>
                  <option value="2">Silver</option>
                  <option value="1">Bronze</option>
                  <option value="na">N/A</option>
                </Form.Select>
              </Col>
              <Col
                xxl={6}
                xl={6}
                lg={4}
                md={4}
                className="d-flex justify-content-end"
              >
                <Form.Select onChange={(e) => {
                  setPage(1)
                  setStatusFilter(e.target.value);
                  //setSearch();
                }} aria-label="Default select example">
                  <option value="">Status</option>
                  <option value="1">Published</option>
                  <option value="2">Hidden </option>
                  <option value="3">Incomplete </option>
                </Form.Select>

                <div className="cmn-btn">
                  <button className="ms-2 black-btn " onClick={() => {
                    dispatch(
                      getAllSalonCSVExport({
                        page: search ? 1 : page,
                        limit: 10000000,
                        search: search,
                        user_type: 1,
                        subscription: subFilter,
                        satusFilter: satusFilter,
                        sortInsurance: sortInsurance
                      })
                    ).then((item) => {
                      console.log(item, "itemmmmmmmmmmm");
                      if (item?.payload?.url) {
                        window.location.href = item?.payload?.url
                      }
                    });
                  }}>DOWNLOAD CSV</button>
                </div>

              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/allsalons" className="active-tab">
                  ALL
                </Link>
              </li>
              <li>
                <Link to="/temporarybanned">Temporary Banned</Link>
              </li>
              <li>
                <Link to="/salonsactive">Active</Link>
              </li>

            </ul>
          </div>

        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                Showing {1 + (page - 1) * limit} -{" "}
                {users?.length + (page - 1) * limit} of {totalUsers} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  onChange={(e) => setLimit(e.target.value)}
                  aria-label="Default select example"
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Business Name</th>
                <th>Email</th>
                {/* <th>Phone Number </th> */}
                <th onClick={() => setSortInsurance(sortInsurance == undefined ? 1 : !sortInsurance)}>Insurance Expiry </th>
                <th>Location</th>
                <th>Logo</th>
                <th>Subscription Level</th>
                <th>Featured</th>
                <th>Last Active</th>
                <th>Status</th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody>
              {users?.map((item, i) => {
                return (
                  <tr
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(`/salonprofile/${item?._id}`)}
                    key={item?._id}
                  >
                    <td>{i + 1 + (page - 1) * limit}</td>
                    <td>
                      <Link to={`/salonprofile/${item?._id}`}>
                        {item?.business_name || "N/A"}
                      </Link>
                    </td>
                    <td>{item?.email || "N/A"}</td>
                    {/* <td>{item?.phone_number}</td> */}
                    <td>
                      {" "}
                      {(item?.insurance_expiry &&
                        moment(item?.insurance_expiry).format("DD/MM/YYYY")) ||
                        "Expired"}
                    </td>
                    <td>{item?.city || "N/A"}</td>
                    <td className="user-img">
                      <img
                        src={
                          item?.profile_logo
                            ? BASE_URL + "/" + item?.profile_logo
                            : require("../Assets/Images/salon-image.png")
                        }
                      />
                    </td>
                    <td>
                      {item?.subscriptionId == null && item?.subscription ? "Expired"
                        : item?.subscription == 1
                          ? "Bronze"
                          : item?.subscription == 2
                            ? "Silver"
                            : item?.subscription == 3
                              ? "Gold"
                              : "N/A"}
                    </td>
                    <td>{item?.featured_salon == 1 ? 'Yes' : 'No'}</td>
                    <td>
                      {item?.lastLogin && moment(item?.lastLogin).format("DD/MM/YYYY, h:mm") ||
                        "N/A"}
                    </td>
                    <td>{item?.subscriptionId === undefined ? "Incomplete" : item?.userExistInSite ? "Published" : "Hidden"}</td>
                    {/* <td>{item?.userExistInSite ? "Published" : "Hidden"}</td> */}
                    {/* <td className="select-box">
                      <Form.Select aria-label="Default select example">
                        <option>Temporary Banned</option>
                        <option value="1">Active</option>
                      </Form.Select>
                    </td> */}
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Pagination
            class="next-btn-new"
            totalStuff={totalUsers}
            limit={limit}
            setPage={updatePage}
            page={page}
          />
        </div>
      </Container>
    </Layout>
  );
}
