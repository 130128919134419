import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { deleteReview, getAllReviews } from "../redux/actions/adminActions";
import Pagination from "../Components/Layout/elements/Pagination";

export default function AllReviews() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { reviews, totalReviews } = useSelector((state) => ({
    reviews: state.reviewSlice.reviews,
    totalReviews: state.reviewSlice.totalReviews,
  }));

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);

  useEffect(() => {
    dispatch(
      getAllReviews({
        page: search ? 1 : page,
        limit: limit,
        search: search,
        user_type: 1,
      })
    );
  }, [page, limit, search]);

  console.log(reviews, totalReviews, "reviews, totalReviews");
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>All Reviews</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                <Form.Control
                    type="search"
                    placeholder="Keyword Search.."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/allreviews" className="active-tab">
                  ALL <span></span>
                </Link>
              </li>
              <li>
                <Link to="/approvedreviews">
                  Approved <span></span>
                </Link>
              </li>
              <li>
                <Link to="/rejectedreviews">
                  Rejected <span></span>
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                Showing {1 + (page - 1) * limit} -{" "}
                {reviews?.length + (page - 1) * limit} of {totalReviews} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  onChange={(e) => setLimit(e.target.value)}
                  aria-label="Default select example"
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Salon Name</th>
                <th>Title</th>
                <th>Client Name</th>
                <th>Location </th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>

              {reviews?.map((item, i) => {
                return (
                  <tr style={{ cursor: "pointer" }}  key={item?._id}>
                    <td>{i + 1 + (page - 1) * limit}</td>
                    <td>
                      <Link to={`/reviewdetail/${item?._id}`}>
                        {item?.salonId?.business_name || "N/A"}
                      </Link>
                    </td>
                    <td>

                      {item?.title || "N/A"}

                    </td>
                    <td>{item?.name || "N/A"}</td>
                    <td>{item?.address}</td>
                    <td>
                      {item?.status == 1 ? 'Approved' : item?.status == 2 ? "Rejected" : "Pending"}
                    </td>

                    <td>
                      <Button onClick={()=>dispatch(deleteReview(item?._id))} className="account-btn-delete">DELETE</Button>{" "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          
          </Table>

          {/* <div className="progress-line">Loading......</div> */}
        </div>
      </Container>
      <Pagination
              class="next-btn-new"
              totalStuff={totalReviews}
              limit={limit}
              setPage={setPage}
            />
      {/* <div className="next-btn-fix">
        <Link to="#">Next</Link>
      </div> */}
    </Layout>
  );
}
