import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteBrand,
  getAllProfileUpdates,
  getBrand,
  profileUpdates,
} from "../redux/actions/adminActions";
import Pagination from "../Components/Layout/elements/Pagination";
import moment from "moment";

export default function ProfileUpdates() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const BASE_URL = process.env.REACT_APP_HOST_NAME;
  const { profileUpdatesArr, profileUpdatesCount } = useSelector((state) => ({
    profileUpdatesArr: state.userMgmtSlice.profileUpdatesArr,
    profileUpdatesCount: state.userMgmtSlice.profileUpdatesCount,
  }));

  const [search, setSearch] = useState("");

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);

  useEffect(() => {
    dispatch(
      getAllProfileUpdates({
        page: search ? 1 : page,
        limit: limit,
        search: search,
        user_type: 0,
      })
    );
  }, [page, limit, search]);

  const handleStatus = (status, id) => {
    dispatch(profileUpdates({ status, id }));
  };

  console.log(profileUpdatesArr, profileUpdatesCount, "kkkkkkkkkkkkkkk");
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Profile Updates</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Keyword Search.."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col
                xxl={9}
                xl={9}
                lg={8}
                md={8}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <Link to="#">Download</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                Showing {1 + (page - 1) * limit} -{" "}
                {profileUpdatesArr?.length + (page - 1) * limit} of{" "}
                {profileUpdatesCount} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  onChange={(e) => setLimit(e.target.value)}
                  aria-label="Default select example"
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Date</th>
                <th>Salon Name</th>
                <th>Treatments Added</th>
                <th>Treatments Removed</th>

                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {profileUpdatesArr?.map((item, i) => {
                return (
                  <tr key={item?._id}>
                    <td>{i + 1 + (page - 1) * limit}</td>
                    <td>{moment(item?.createAt).format("DD/MM/YYYY")}</td>
                    <td className="user-img">
                      {item?.salonId?.full_name || "N/A"}
                    </td>
                    <td>{item?.addedTreatment?.join(", ")}</td>
                    <td>{item?.removeTreatmtnt?.join(", ")}</td>

                    <td>
                      <Button
                        className="account-btn"
                        onClick={() => handleStatus(1, item?._id)}
                      >
                        Authorise
                      </Button>{" "}
                      <Button
                        className="account-btn-delete"
                        onClick={() => handleStatus(2, item?._id)}
                      >
                        Decline
                      </Button>{" "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {profileUpdatesArr?.length == 0 && (
            <p className="no-data-found">No data available yet!</p>
          )}
          <Pagination
            class="next-btn-new"
            totalStuff={profileUpdatesCount}
            limit={limit}
            setPage={setPage}
          />
        </div>
      </Container>
    </Layout>
  );
}
