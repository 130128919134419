import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { addSupportNotes, getSupportById } from "../redux/actions/adminActions";
import moment from "moment";
import toast from "react-hot-toast";

export default function TicketDetail() {
  const dispatch = useDispatch();
  const {id} = useParams();
  const [notes, setNotes] = useState("");
  const [flag, setFlag] = useState(true);

  const { supportDetail } = useSelector((state) => ({
    supportDetail: state.supportSlice.supportDetail
  }));
  
  useEffect(()=>{
    dispatch(getSupportById(id))
  },[id,flag])

  console.log(supportDetail,"supportDetail");

  const handleStatus = (status) =>{
    dispatch(
      addSupportNotes({ id: id, status })
    ).then((res) => {
      if (res?.payload?.success) {
        toast.success("Updated Successfully!");
        setFlag(!flag);
        setNotes("");
      } else {
        toast.error(res?.payload?.message);
      }
    })
  }

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Ticket Detail</h2>
          </div>
          <div></div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={12}>
              <div className="product-tab-left">
                <Link to="/support">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid>
        <Row>
          <Col lg={8}>
            <Row>
              <Col lg={12}>
                <div className="customer-form">
                  <h2>DETAILS</h2>
                  <Row>
                    <Col lg={6}>
                      <div className="member-info-inner">
                        <h2>DETAILS</h2>
                        <div className="member-inner">
                          <p>
                            Member: <span>{supportDetail?.full_name}</span>
                          </p>
                          <p>
                            Email: <span>{supportDetail?.email}</span>
                          </p>
                          <p>
                            Phone: <span>+44 {supportDetail?.phone_number}</span>
                          </p>
                        </div>
                      </div>
                    </Col>
                    {/* <Col lg={6}>
                      <div className="member-info-inner">
                        <h2>ADDRESS</h2>
                        <div className="address-main">
                          <p>
                            Old Trafford <br />
                            Stretford
                            <br /> Manchester
                            <br /> M16 0RA
                          </p>
                        </div>
                      </div>
                    </Col> */}
                  </Row>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <div className="profile-products-sec">
                  <h2>TICKET DETAILS </h2>
                  <hr />
                  <div className="admin-box-main">
                    <div className="admin-box-inner">
                      <div className="admin-box-top">
                        <h3 className="text-start">
                         
                          { moment(supportDetail?.createdAt).format('D/MM/YYYY h:mmA')}
                        </h3>
                      </div>
                      <p style={{whiteSpace:"pre-wrap"}} className="text-start">
                       {
                        supportDetail?.comment || 'N/A'
                       }
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
            <Col lg={12}>
          <div className="profile-products-sec mx-3">
            <h2> NOTES </h2>
            <hr />
            <div className="admin-box-main">
              <div className="admin-box-inner">
                {supportDetail?.admin_notes ? (
                  supportDetail?.admin_notes?.map((notes) => {
                    console.log(notes?.createdAt);
                    return (
                      <>
                        <div className="admin-box-top">
                          <h3>
                            {notes?.admin_name}
                            <span>
                              {new Date(
                                notes?.createdAt
                              ).toLocaleDateString() || "N/A"}
                            </span>{" "}
                            <span>
                              {moment(notes?.createdAt).format("h:mm") || "N/A"}
                            </span>
                          </h3>
                        </div>
                        <p style={{ whiteSpace: "pre-line" }}>{notes?.notes}</p>
                      </>
                    );
                  })
                ) : (
                  <div>No data updated yet</div>
                )}
              </div>

              <hr />

              <div className="admin-box-inner">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Control
                    onChange={(e) => {
                      setNotes(e.target.value);
                    }}
                    as="textarea"
                    name="notes"
                    value={notes}
                    rows={3}
                    placeholder="Type Here"
                  />
                  <Col lg={12} className="d-flex justify-content-center mt-4 ">
                    <div class="cmn-btn">
                      <button
                        onClick={() =>
                          dispatch(
                            addSupportNotes({ id: id, notes: notes })
                          ).then((res) => {
                            if (res?.payload?.success) {
                              toast.success(res?.payload?.message);
                              setFlag(!flag);
                              setNotes("");
                            } else {
                              toast.error(res?.payload?.message);
                            }
                          })
                        }
                        type="submit"
                      >
                        SUBMIT
                      </button>
                    </div>
                  </Col>
                </Form.Group>
              </div>
            </div>
          </div>
        </Col>
            </Row>
          </Col>
          <Col lg={4}>
            <div className="product-overview-right">
              <div className="product-overview-box">
                <h2>STATUS</h2>
                <Form.Select onChange={(e)=>handleStatus(e.target.value)} value={supportDetail?.status} aria-label="Default select example">
                  {/* <option></option> */}
                  <option value="1" >OPEN</option>
                  <option value="0">CLOSE</option>
                </Form.Select>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
