import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import { getAllSupport, getSupportById, getSupportTicket } from "../actions/adminActions";

const initialState = {
    supportArr: [],
    totalSupport: null,
    supportTicket:0,
    supportDetail: null
};

export const supportSlice = createSlice({
  name: "supportSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      
      .addCase(getAllSupport.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.supportArr = payload.data.support;
          state.totalSupport = payload.data.totalSupport;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getSupportTicket.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.supportTicket = payload.data;
        } else if (!payload?.success) {
          //toast.error(payload?.message);
        }
      })
      .addCase(getSupportById.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.supportDetail = payload.data;
        } else if (!payload?.success) {
          //toast.error(payload?.message);
        }
      })
      
      
  },
});

export default supportSlice.reducer;
