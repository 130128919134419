import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Sidebar({
  isActive,
  setIsActive,
  setShow,
  show,
  adminDetails,
  supportTicket,
}) {
  // const { access_permissions } = adminDetails;
  // console.log(access_permissions, "access_permissions");
  return (
    <aside>
      <div className="sidebar">
        <div className="toggle-icon" onClick={() => setShow(!show)}>
          <img src={require("../../Assets/Images/toggle.svg").default} />
        </div>
        <div className="logo-side">
          <img
            className="small"
            src={require("../../Assets/Images/favicon.png")}
          />
          <img
            className="big"
            src={require("../../Assets/Images/Logo.svg").default}
          />
        </div>
        <div className="side-menu">
          <div>
            {((adminDetails?.role == "superadmin" ||
              adminDetails?.access_permissions?.dashboard) && (
              <div className="side-btm-space">
                <Link
                  className={
                    window.location.pathname == "/Dashboard"
                      ? "menu-btn bar_active"
                      : "menu-btn "
                  }
                  to="/Dashboard"
                >
                  <svg
                    className="fill-active"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M9.17323 5.48819C9.17323 6.94094 9.17323 8.39764 9.17323 9.85039C9.17323 10.6693 8.85039 10.9882 8.03937 10.9882C5.73622 10.9882 3.42913 10.9882 1.12598 10.9882C0.34252 10.9882 0 10.6457 0 9.86614C0 6.94488 0 4.02756 0 1.1063C0 0.350394 0.350394 0 1.11024 0C3.4252 0 5.74016 0 8.05906 0C8.84252 0 9.17323 0.330709 9.17323 1.12598C9.17717 2.57874 9.17323 4.03543 9.17323 5.48819ZM1.85039 1.84252C1.85039 4.29921 1.85039 6.71654 1.85039 9.12992C3.69685 9.12992 5.51969 9.12992 7.33858 9.12992C7.33858 6.68504 7.33858 4.26772 7.33858 1.84252C5.50394 1.84252 3.68898 1.84252 1.85039 1.84252Z"
                      fill="#5F6368"
                    />
                    <path
                      d="M10.8232 14.5117C10.8232 13.059 10.8232 11.6023 10.8232 10.1495C10.8232 9.33062 11.1461 9.01172 11.961 9.01172C14.2642 9.01172 16.5713 9.01172 18.8744 9.01172C19.654 9.01172 19.9965 9.35424 19.9965 10.1377C19.9965 13.0472 19.9965 15.9527 19.9965 18.8621C19.9965 19.6653 19.6579 19.9999 18.8508 19.9999C16.5595 19.9999 14.2642 19.9999 11.9728 19.9999C11.1382 19.9999 10.8232 19.681 10.8232 18.8385C10.8232 17.3936 10.8232 15.9527 10.8232 14.5117ZM18.15 18.1574C18.15 15.7007 18.15 13.2873 18.15 10.866C16.3036 10.866 14.4807 10.866 12.6579 10.866C12.6579 13.3109 12.6579 15.7243 12.6579 18.1574C14.4965 18.1574 16.3114 18.1574 18.15 18.1574Z"
                      fill="#5F6368"
                    />
                    <path
                      d="M15.3941 7.32677C14.2484 7.32677 13.0988 7.33071 11.9532 7.32677C11.1421 7.32284 10.8272 7.01575 10.8272 6.21654C10.8232 4.51181 10.8232 2.81102 10.8272 1.1063C10.8272 0.334646 11.1579 0.00393701 11.9256 0C14.2445 0 16.5634 0 18.8823 0C19.6618 0 19.9965 0.34252 20.0004 1.13386C20.0004 2.82677 20.0004 4.51575 20.0004 6.20866C20.0004 6.99606 19.6697 7.32284 18.8744 7.32677C17.713 7.33071 16.5516 7.32677 15.3941 7.32677ZM18.15 1.85827C16.2996 1.85827 14.4728 1.85827 12.6579 1.85827C12.6579 3.09055 12.6579 4.29134 12.6579 5.5C14.5004 5.5 16.3154 5.5 18.15 5.5C18.15 4.28346 18.15 3.08268 18.15 1.85827Z"
                      fill="#5F6368"
                    />
                    <path
                      d="M4.59449 12.6736C5.75197 12.6736 6.91339 12.6697 8.07087 12.6736C8.85433 12.6776 9.17323 12.9886 9.17323 13.7721C9.17717 15.4768 9.17717 17.1776 9.17323 18.8823C9.17323 19.6736 8.84252 20.0004 8.04724 20.0004C5.74016 20.0004 3.43307 20.0004 1.12598 20.0004C0.34252 20.0004 0 19.6579 0 18.8784C0 17.1736 0 15.4728 0 13.7681C0 13.0201 0.334646 12.6815 1.07874 12.6776C2.25197 12.6697 3.42126 12.6736 4.59449 12.6736ZM7.34252 18.1579C7.34252 16.9177 7.34252 15.7169 7.34252 14.5122C5.49606 14.5122 3.67323 14.5122 1.85039 14.5122C1.85039 15.7406 1.85039 16.9413 1.85039 18.1579C3.68504 18.1579 5.50394 18.1579 7.34252 18.1579Z"
                      fill="#5F6368"
                    />
                  </svg>
                  <p> Dashboard</p>
                </Link>
              </div>
            )) ||
              ""}
            {((adminDetails?.role == "superadmin" ||
              adminDetails?.access_permissions?.analytics) && (
              <div>
                <Link
                  to="/Analytics"
                  className={
                    window.location.pathname == "/Analytics"
                      ? "menu-btn bar_active"
                      : "menu-btn "
                  }
                >
                  <svg
                    className="fill-active"
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.12109 17.5631C6.70709 17.5631 6.37109 17.2271 6.37109 16.8131V9.95312C6.37109 9.53912 6.70709 9.20312 7.12109 9.20312C7.53509 9.20312 7.87109 9.53912 7.87109 9.95312V16.8131C7.87109 17.2271 7.53509 17.5631 7.12109 17.5631Z"
                      fill="#5F6368"
                    />
                    <path
                      d="M7.12109 17.5631C6.70709 17.5631 6.37109 17.2271 6.37109 16.8131V9.95312C6.37109 9.53912 6.70709 9.20312 7.12109 9.20312C7.53509 9.20312 7.87109 9.53912 7.87109 9.95312V16.8131C7.87109 17.2271 7.53509 17.5631 7.12109 17.5631"
                      stroke="#5F6368"
                      stroke-width="0.5"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11.7881 17.561C11.3741 17.561 11.0381 17.225 11.0381 16.811V6.66797C11.0381 6.25397 11.3741 5.91797 11.7881 5.91797C12.2021 5.91797 12.5381 6.25397 12.5381 6.66797V16.811C12.5381 17.225 12.2021 17.561 11.7881 17.561Z"
                      fill="#5F6368"
                    />
                    <path
                      d="M11.7881 17.561C11.3741 17.561 11.0381 17.225 11.0381 16.811V6.66797C11.0381 6.25397 11.3741 5.91797 11.7881 5.91797C12.2021 5.91797 12.5381 6.25397 12.5381 6.66797V16.811C12.5381 17.225 12.2021 17.561 11.7881 17.561"
                      stroke="#5F6368"
                      stroke-width="0.5"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16.3779 17.5621C15.9639 17.5621 15.6279 17.2261 15.6279 16.8121V13.5781C15.6279 13.1641 15.9639 12.8281 16.3779 12.8281C16.7919 12.8281 17.1279 13.1641 17.1279 13.5781V16.8121C17.1279 17.2261 16.7919 17.5621 16.3779 17.5621Z"
                      fill="#5F6368"
                    />
                    <path
                      d="M16.3779 17.5621C15.9639 17.5621 15.6279 17.2261 15.6279 16.8121V13.5781C15.6279 13.1641 15.9639 12.8281 16.3779 12.8281C16.7919 12.8281 17.1279 13.1641 17.1279 13.5781V16.8121C17.1279 17.2261 16.7919 17.5621 16.3779 17.5621"
                      stroke="#5F6368"
                      stroke-width="0.5"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.064 2.5C4.292 2.5 2.5 4.397 2.5 7.335V16.165C2.5 19.103 4.292 21 7.064 21H16.436C19.209 21 21 19.103 21 16.165V7.335C21 4.397 19.209 2.5 16.436 2.5H7.064ZM16.436 22.5H7.064C3.437 22.5 1 19.954 1 16.165V7.335C1 3.546 3.437 1 7.064 1H16.436C20.063 1 22.5 3.546 22.5 7.335V16.165C22.5 19.954 20.063 22.5 16.436 22.5Z"
                      fill="#5F6368"
                    />
                    <path
                      d="M7.064 2.25C5.61877 2.25 4.40864 2.74605 3.56072 3.64412C2.71418 4.54074 2.25 5.81527 2.25 7.335H2.75C2.75 5.91673 3.18182 4.77376 3.92428 3.98738C4.66536 3.20245 5.73723 2.75 7.064 2.75V2.25ZM2.25 7.335V16.165H2.75V7.335H2.25ZM2.25 16.165C2.25 17.6847 2.71418 18.9593 3.56072 19.8559C4.40864 20.754 5.61877 21.25 7.064 21.25V20.75C5.73723 20.75 4.66536 20.2975 3.92428 19.5126C3.18182 18.7262 2.75 17.5833 2.75 16.165H2.25ZM7.064 21.25H16.436V20.75H7.064V21.25ZM16.436 21.25C17.8817 21.25 19.0919 20.754 19.9397 19.8559C20.7861 18.9592 21.25 17.6847 21.25 16.165H20.75C20.75 17.5833 20.3184 18.7263 19.5761 19.5126C18.8351 20.2975 17.7633 20.75 16.436 20.75V21.25ZM21.25 16.165V7.335H20.75V16.165H21.25ZM21.25 7.335C21.25 5.8153 20.7861 4.54077 19.9397 3.64414C19.0919 2.74604 17.8817 2.25 16.436 2.25V2.75C17.7633 2.75 18.8351 3.20246 19.5761 3.98736C20.3184 4.77373 20.75 5.9167 20.75 7.335H21.25ZM16.436 2.25H7.064V2.75H16.436V2.25ZM16.436 22.25H7.064V22.75H16.436V22.25ZM7.064 22.25C5.3112 22.25 3.86299 21.6362 2.85247 20.5805C1.84104 19.5239 1.25 18.0054 1.25 16.165H0.75C0.75 18.1136 1.37746 19.7626 2.49128 20.9262C3.60601 22.0908 5.1898 22.75 7.064 22.75V22.25ZM1.25 16.165V7.335H0.75V16.165H1.25ZM1.25 7.335C1.25 5.4946 1.84104 3.97614 2.85247 2.9195C3.86299 1.86381 5.3112 1.25 7.064 1.25V0.75C5.1898 0.75 3.60601 1.40919 2.49128 2.57375C1.37746 3.73736 0.75 5.3864 0.75 7.335H1.25ZM7.064 1.25H16.436V0.75H7.064V1.25ZM16.436 1.25C18.1888 1.25 19.637 1.86381 20.6475 2.9195C21.659 3.97614 22.25 5.4946 22.25 7.335H22.75C22.75 5.3864 22.1225 3.73736 21.0087 2.57375C19.894 1.40919 18.3102 0.75 16.436 0.75V1.25ZM22.25 7.335V16.165H22.75V7.335H22.25ZM22.25 16.165C22.25 18.0054 21.659 19.5239 20.6475 20.5805C19.637 21.6362 18.1888 22.25 16.436 22.25V22.75C18.3102 22.75 19.894 22.0908 21.0087 20.9262C22.1225 19.7626 22.75 18.1136 22.75 16.165H22.25Z"
                      fill="#5F6368"
                    />
                  </svg>
                  <p>Analytics</p>
                </Link>
              </div>
            )) ||
              ""}
            {((adminDetails?.role == "superadmin" ||
              adminDetails?.access_permissions?.manage_salons) && (
              <div>
                <Link
                  className={
                    window.location.pathname == "/allsalons"
                      ? "menu-btn bar_active"
                      : "menu-btn "
                  }
                  to="/allsalons"
                >
                  <svg
                    className="fill-active"
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="21"
                    viewBox="0 0 19 21"
                    fill="none"
                  >
                    <path
                      d="M4.78393 8.64945C4.79858 8.64945 4.81291 8.64913 4.82723 8.64815L15.5204 8.03963C15.9205 8.01684 16.2324 7.68605 16.2324 7.28591V1.36354C16.2324 0.963402 15.9205 0.632283 15.5204 0.609818L4.82691 0.00130233C4.81291 0.000325584 4.79858 0 4.78393 0C2.6582 0 0.928047 1.72982 0.928047 3.85621V4.79357C0.928047 6.91963 2.65787 8.64945 4.78393 8.64945ZM14.7227 6.57255L4.76375 7.13939C3.47964 7.12898 2.43778 6.0806 2.43778 4.79357V3.85621C2.43778 2.56918 3.47932 1.52113 4.76375 1.51038L14.7227 2.07722V6.57255Z"
                      fill="#5F6368"
                    />
                    <path
                      d="M15.4775 8.04016H18.2446C18.662 8.04016 19 7.70221 19 7.28514V1.38198C19 0.964909 18.662 0.626953 18.2446 0.626953H15.4775C15.0601 0.626953 14.7225 0.964909 14.7225 1.38198V7.28546C14.7225 7.70221 15.0601 8.04016 15.4775 8.04016ZM17.4903 6.53043H16.2322V2.13668H17.4903V6.53043ZM6.95634 17.5166C7.08495 17.5166 7.21453 17.5078 7.34541 17.4889C8.06756 17.3851 8.70603 17.0064 9.14296 16.4226C9.57989 15.8385 9.76287 15.12 9.65966 14.3988L8.68812 7.60779C8.62984 7.19462 8.24728 6.90551 7.83379 6.96704C7.42062 7.02597 7.13444 7.40853 7.19337 7.82137L8.16458 14.613C8.21179 14.936 8.12974 15.257 7.93407 15.5178C7.73904 15.779 7.45318 15.9479 7.13053 15.9945C6.46601 16.0905 5.84382 15.6253 5.74778 14.9578L4.70689 7.67323C4.64763 7.26007 4.26735 6.97258 3.85255 7.03248C3.43939 7.09141 3.1532 7.47365 3.21181 7.88681L4.2527 15.1717C4.44772 16.5324 5.61982 17.5166 6.95634 17.5166Z"
                      fill="#5F6368"
                    />
                    <path
                      d="M8.28181 10.8577H9.69061C10.1074 10.8577 10.4453 10.5198 10.4453 10.1027C10.4453 9.68561 10.1074 9.34766 9.69061 9.34766H8.28181C7.86506 9.34766 7.52711 9.68561 7.52711 10.1027C7.52711 10.5198 7.86474 10.8577 8.28181 10.8577ZM2.84554 20.8368C3.56508 20.8368 4.44448 20.7857 5.47463 20.633C6.61352 20.4634 7.41283 19.9871 7.85106 19.2161C8.53967 18.0039 7.99953 16.6091 7.93571 16.4542C7.7765 16.0687 7.33566 15.885 6.94985 16.0436C6.56533 16.2022 6.38138 16.6411 6.53799 17.0256C6.6324 17.2639 6.81603 17.9877 6.53538 18.4754C6.33743 18.8189 5.90603 19.0422 5.25323 19.1393C2.50173 19.5475 0.967903 19.1617 0.953577 19.1578C0.551156 19.0484 0.136689 19.2854 0.0263166 19.6875C-0.0834055 20.09 0.153619 20.5048 0.55604 20.6145C0.605203 20.6275 1.39767 20.8368 2.84554 20.8368Z"
                      fill="#5F6368"
                    />
                  </svg>
                  <p>Manage Salons</p>
                </Link>
              </div>
            )) ||
              ""}

            {((adminDetails?.role == "superadmin" ||
              adminDetails?.access_permissions?.manage_salons) && (
              <div>
                <Link
                  className={
                    window.location.pathname == "/profileUpdates"
                      ? "menu-btn bar_active"
                      : "menu-btn "
                  }
                  to="/profileUpdates"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="22"
                    viewBox="0 0 21 22"
                    fill="none"
                  >
                    <path
                      d="M10.0234 11.8415C6.89761 11.8415 4.35268 9.29654 4.35268 6.17073C4.35268 3.04492 6.89761 0.5 10.0234 0.5C13.1492 0.5 15.6941 3.04492 15.6941 6.17073C15.6941 9.29654 13.1492 11.8415 10.0234 11.8415ZM10.0234 1.10976C7.23605 1.10976 4.96244 3.38337 4.96244 6.17073C4.96244 8.95809 7.23605 11.2317 10.0234 11.2317C12.8108 11.2317 15.0844 8.95809 15.0844 6.17073C15.0844 3.38337 12.8108 1.10976 10.0234 1.10976ZM19.242 21.5C19.0781 21.5 18.9371 21.359 18.9371 21.1951C18.9371 17.1165 14.8236 13.9878 10.0234 13.9878C5.22319 13.9878 1.10976 17.1165 1.10976 21.1951C1.10976 21.359 0.968735 21.5 0.804878 21.5C0.64102 21.5 0.5 21.359 0.5 21.1951C0.5 16.972 4.68148 13.378 10.0234 13.378C15.3653 13.378 19.5468 16.972 19.5468 21.1951C19.5468 21.359 19.4058 21.5 19.242 21.5Z"
                      stroke="#5F6368"
                    />
                  </svg>
                  <p>Profile Updates</p>
                </Link>
              </div>
            )) ||
              ""}

            {((adminDetails?.role == "superadmin" ||
              adminDetails?.access_permissions?.manage_reviews) && (
              <div>
                <Link
                  className={
                    window.location.pathname == "/allreviews"
                      ? "menu-btn bar_active"
                      : "menu-btn "
                  }
                  to="/allreviews"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                  >
                    <path
                      d="M10.0787 1.39229L12.1379 5.53039C12.2389 5.73837 12.4376 5.88377 12.6688 5.91883L17.278 6.58473C17.4651 6.61023 17.634 6.70833 17.7473 6.8572C17.8606 7.00606 17.9089 7.19337 17.8813 7.37747C17.8582 7.5288 17.7846 7.66825 17.6722 7.77384L14.3339 11.0082C14.1652 11.1676 14.0896 11.4002 14.1328 11.6266L14.9372 16.1848C14.9954 16.5604 14.738 16.9128 14.3581 16.9775C14.2014 17.0017 14.041 16.9768 13.8996 16.9062L9.78912 14.7579C9.58378 14.6464 9.33485 14.6464 9.12951 14.7579L4.99493 16.922C4.64804 17.0975 4.2225 16.9671 4.0377 16.6287C3.96565 16.4923 3.94025 16.3366 3.9653 16.1848L4.7697 11.6266C4.8086 11.4005 4.73361 11.1699 4.5686 11.0082L1.20623 7.77384C0.931257 7.49911 0.931257 7.05764 1.20623 6.78292C1.31845 6.68052 1.45797 6.61177 1.60843 6.58473L6.21761 5.91883C6.44774 5.88121 6.64538 5.7366 6.74851 5.53039L8.80776 1.39229C8.88912 1.22451 9.03579 1.09621 9.2145 1.03651C9.39321 0.976807 9.58882 0.990758 9.75694 1.0752C9.89565 1.14453 10.0084 1.25559 10.0787 1.39229Z"
                      stroke="#5F6368"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <p>Manage Reviews</p>
                </Link>
              </div>
            )) ||
              ""}
            {((adminDetails?.role == "superadmin" ||
              adminDetails?.access_permissions?.blogs) && (
              <div className="side-btm-space">
                <Link
                  className={
                    window.location.pathname == "/allblogs"
                      ? "menu-btn bar_active"
                      : "menu-btn "
                  }
                  to="/allblogs"
                >
                  <svg
                    className="fill-active"
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.03905 12.0113C6.0398 11.5973 6.3764 11.2619 6.7904 11.2626L13.6504 11.275C14.0644 11.2758 14.3998 11.6124 14.399 12.0264C14.3983 12.4404 14.0617 12.7758 13.6477 12.775L6.78769 12.7626C6.3737 12.7619 6.0383 12.4253 6.03905 12.0113"
                      fill="#5F6368"
                    />
                    <path
                      d="M6.03905 12.0113C6.0398 11.5973 6.3764 11.2619 6.7904 11.2626L13.6504 11.275C14.0644 11.2758 14.3998 11.6124 14.399 12.0264C14.3983 12.4404 14.0617 12.7758 13.6477 12.775L6.78769 12.7626C6.3737 12.7619 6.0383 12.4253 6.03905 12.0113"
                      stroke="#5F6368"
                      stroke-width="0.5"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.00521 16.7489C6.0082 16.3349 6.34662 16.0014 6.7606 16.0044L16.9033 16.0776C17.3173 16.0806 17.6509 16.419 17.6479 16.833C17.6449 17.247 17.3065 17.5805 16.8925 17.5776L6.74977 17.5043C6.33578 17.5013 6.00222 17.1629 6.00521 16.7489"
                      fill="#5F6368"
                    />
                    <path
                      d="M6.00521 16.7489C6.0082 16.3349 6.34662 16.0014 6.7606 16.0044L16.9033 16.0776C17.3173 16.0806 17.6509 16.419 17.6479 16.833C17.6449 17.247 17.3065 17.5805 16.8925 17.5776L6.74977 17.5043C6.33578 17.5013 6.00222 17.1629 6.00521 16.7489"
                      stroke="#5F6368"
                      stroke-width="0.5"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.00325 7.75042C6.00492 7.33642 6.34227 7.00178 6.75627 7.00345L9.99024 7.0165C10.4042 7.01818 10.7389 7.35553 10.7372 7.76953C10.7355 8.18352 10.3982 8.51816 9.98419 8.51649L6.75021 8.50344C6.33622 8.50177 6.00157 8.16441 6.00325 7.75042"
                      fill="#5F6368"
                    />
                    <path
                      d="M6.00325 7.75042C6.00492 7.33642 6.34227 7.00178 6.75627 7.00345L9.99024 7.0165C10.4042 7.01818 10.7389 7.35553 10.7372 7.76953C10.7355 8.18352 10.3982 8.51816 9.98419 8.51649L6.75021 8.50344C6.33622 8.50177 6.00157 8.16441 6.00325 7.75042"
                      stroke="#5F6368"
                      stroke-width="0.5"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.064 2.5C4.292 2.5 2.5 4.397 2.5 7.335V16.165C2.5 19.103 4.292 21 7.064 21H16.436C19.209 21 21 19.103 21 16.165V7.335C21 4.397 19.209 2.5 16.436 2.5H7.064ZM16.436 22.5H7.064C3.437 22.5 1 19.954 1 16.165V7.335C1 3.546 3.437 1 7.064 1H16.436C20.063 1 22.5 3.546 22.5 7.335V16.165C22.5 19.954 20.063 22.5 16.436 22.5V22.5Z"
                      fill="#5F6368"
                    />
                    <path
                      d="M7.064 2.25C5.61877 2.25 4.40864 2.74605 3.56072 3.64412C2.71418 4.54074 2.25 5.81527 2.25 7.335H2.75C2.75 5.91673 3.18182 4.77376 3.92428 3.98738C4.66536 3.20245 5.73723 2.75 7.064 2.75V2.25ZM2.25 7.335V16.165H2.75V7.335H2.25ZM2.25 16.165C2.25 17.6847 2.71418 18.9593 3.56072 19.8559C4.40864 20.754 5.61877 21.25 7.064 21.25V20.75C5.73723 20.75 4.66536 20.2975 3.92428 19.5126C3.18182 18.7262 2.75 17.5833 2.75 16.165H2.25ZM7.064 21.25H16.436V20.75H7.064V21.25ZM16.436 21.25C17.8817 21.25 19.0919 20.754 19.9397 19.8559C20.7861 18.9592 21.25 17.6847 21.25 16.165H20.75C20.75 17.5833 20.3184 18.7263 19.5761 19.5126C18.8351 20.2975 17.7633 20.75 16.436 20.75V21.25ZM21.25 16.165V7.335H20.75V16.165H21.25ZM21.25 7.335C21.25 5.8153 20.7861 4.54077 19.9397 3.64414C19.0919 2.74604 17.8817 2.25 16.436 2.25V2.75C17.7633 2.75 18.8351 3.20246 19.5761 3.98736C20.3184 4.77373 20.75 5.9167 20.75 7.335H21.25ZM16.436 2.25H7.064V2.75H16.436V2.25ZM16.436 22.25H7.064V22.75H16.436V22.25ZM7.064 22.25C5.3112 22.25 3.86299 21.6362 2.85247 20.5805C1.84104 19.5239 1.25 18.0054 1.25 16.165H0.75C0.75 18.1136 1.37746 19.7626 2.49128 20.9262C3.60601 22.0908 5.1898 22.75 7.064 22.75V22.25ZM1.25 16.165V7.335H0.75V16.165H1.25ZM1.25 7.335C1.25 5.4946 1.84104 3.97614 2.85247 2.9195C3.86299 1.86381 5.3112 1.25 7.064 1.25V0.75C5.1898 0.75 3.60601 1.40919 2.49128 2.57375C1.37746 3.73736 0.75 5.3864 0.75 7.335H1.25ZM7.064 1.25H16.436V0.75H7.064V1.25ZM16.436 1.25C18.1888 1.25 19.637 1.86381 20.6475 2.9195C21.659 3.97614 22.25 5.4946 22.25 7.335H22.75C22.75 5.3864 22.1225 3.73736 21.0087 2.57375C19.894 1.40919 18.3102 0.75 16.436 0.75V1.25ZM22.25 7.335V16.165H22.75V7.335H22.25ZM22.25 16.165C22.25 18.0054 21.659 19.5239 20.6475 20.5805C19.637 21.6362 18.1888 22.25 16.436 22.25V22.75C18.3102 22.75 19.894 22.0908 21.0087 20.9262C22.1225 19.7626 22.75 18.1136 22.75 16.165H22.25Z"
                      fill="#5F6368"
                    />
                  </svg>
                  <p> Blogs</p>
                </Link>
              </div>
            )) ||
              ""}
            {((adminDetails?.role == "superadmin" ||
              adminDetails?.access_permissions?.mailing_list) && (
              <div className="side-btm-space">
                <Link
                  className={
                    window.location.pathname == "/mailinglist"
                      ? "menu-btn bar_active"
                      : "menu-btn "
                  }
                  to="/mailinglist"
                >
                  <svg
                    className="fill-active"
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="19"
                    viewBox="0 0 22 19"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10.6586 12.6139C9.98957 12.6139 9.32257 12.3929 8.76457 11.9509L4.27957 8.33491C3.95657 8.07491 3.90657 7.60191 4.16557 7.27991C4.42657 6.95891 4.89857 6.90791 5.22057 7.16691L9.70157 10.7789C10.2646 11.2249 11.0576 11.2249 11.6246 10.7749L16.0606 7.16891C16.3826 6.90591 16.8546 6.95591 17.1166 7.27791C17.3776 7.59891 17.3286 8.07091 17.0076 8.33291L12.5636 11.9449C12.0016 12.3909 11.3296 12.6139 10.6586 12.6139"
                      fill="#5F6368"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.06359 1.3252C3.29159 1.3252 1.49959 3.00161 1.49959 5.59799V13.4012C1.49959 15.9976 3.29159 17.674 6.06359 17.674H15.4356C18.2086 17.674 19.9996 15.9976 19.9996 13.4012V5.59799C19.9996 3.00161 18.2086 1.3252 15.4356 1.3252H6.06359ZM15.436 19H6.064C2.437 19 0 16.75 0 13.4016V5.59837C0 2.24995 2.437 0 6.064 0H15.436C19.063 0 21.5 2.24995 21.5 5.59837V13.4016C21.5 16.75 19.063 19 15.436 19V19Z"
                      fill="#5F6368"
                    />
                  </svg>
                  <p>Mailing List</p>
                </Link>
              </div>
            )) ||
              ""}
            {((adminDetails?.role == "superadmin" ||
              adminDetails?.access_permissions?.manage_brands) && (
              <div>
                <Link
                  className={
                    window.location.pathname == "/allbrands"
                      ? "menu-btn bar_active"
                      : "menu-btn "
                  }
                  to="/allbrands"
                >
                  <svg
                    className="fill-active"
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="17"
                    viewBox="0 0 22 17"
                    fill="none"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="21"
                      height="16"
                      rx="1.5"
                      stroke="#5F6368"
                    />
                    <path
                      d="M9 11.0547L10.0021 10.5391C10.097 10.9219 10.2711 11.2148 10.5243 11.418C10.7774 11.6172 11.1009 11.7168 11.4947 11.7168C11.8674 11.7168 12.1628 11.6016 12.3808 11.3711C12.6023 11.1406 12.7131 10.832 12.7131 10.4453C12.7131 9.94141 12.3368 9.49219 11.5844 9.09766C11.4789 9.03906 11.398 8.99609 11.3418 8.96875C10.4909 8.50781 9.923 8.0918 9.63819 7.7207C9.35689 7.3457 9.21624 6.88867 9.21624 6.34961C9.21624 5.65039 9.42897 5.08398 9.85443 4.65039C10.2834 4.2168 10.8442 4 11.5369 4C12.1065 4 12.583 4.12305 12.9662 4.36914C13.3495 4.61133 13.615 4.96094 13.7627 5.41797L12.7816 5.98047C12.6269 5.71094 12.4564 5.51367 12.27 5.38867C12.0872 5.26367 11.8762 5.20117 11.6371 5.20117C11.2996 5.20117 11.0306 5.29883 10.8302 5.49414C10.6333 5.68945 10.5348 5.95117 10.5348 6.2793C10.5348 6.79492 10.9708 7.27539 11.8428 7.7207C11.9096 7.75586 11.9624 7.7832 12.0011 7.80273C12.7641 8.19336 13.288 8.57617 13.5728 8.95117C13.8576 9.32227 14 9.78711 14 10.3457C14 11.1582 13.7697 11.8047 13.3091 12.2852C12.8485 12.7617 12.2261 13 11.442 13C10.7845 13 10.2447 12.832 9.82278 12.4961C9.40436 12.1602 9.1301 11.6797 9 11.0547Z"
                      fill="#5F6368"
                    />
                  </svg>
                  <p>Manage Brands</p>
                </Link>
              </div>
            )) ||
              ""}
            {((adminDetails?.role == "superadmin" ||
              adminDetails?.access_permissions?.manage_admins) && (
              <div className="side-btm-space">
                <Link
                  className={
                    window.location.pathname == "/Administrators"
                      ? "menu-btn bar_active"
                      : "menu-btn "
                  }
                  to="/Administrators"
                >
                  <svg
                    className="fill-active"
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_1989_429)">
                      <path
                        d="M12.7919 9.9485C14.3348 10.6032 15.6164 11.5817 16.6228 12.8945C16.1746 13.1127 15.7413 13.3241 15.3195 13.5287C12.6234 10.7827 9.44593 9.89962 5.89129 11.5704C2.55444 13.1377 1.2236 15.9814 1.46776 19.6014C1.4414 19.6059 1.40013 19.6173 1.35772 19.6173C0.932445 19.6184 0.507172 19.6184 0.0853385 19.6184C-0.429345 16.1337 1.40586 11.7613 5.73195 9.94281C4.12486 8.52665 3.42562 6.76837 3.75117 4.66231C3.97584 3.20977 4.71405 2.03001 5.89014 1.13326C8.16782 -0.603423 11.39 -0.309051 13.372 1.79929C15.4341 3.99059 15.4364 7.80492 12.7919 9.9485ZM13.5118 5.59316C13.5003 3.26433 11.6044 1.3958 9.26252 1.4049C6.91492 1.41285 5.02584 3.29729 5.03501 5.61589C5.04533 7.94245 6.94473 9.81438 9.28545 9.80529C11.6308 9.7962 13.5233 7.90949 13.5118 5.59316Z"
                        fill="#5F6368"
                      />
                      <path
                        d="M19.5025 19.6231C19.1425 19.88 18.7528 20.0436 18.3367 20.1471C18.2909 20.1584 18.1969 20.1278 18.1808 20.0914C18.0433 19.7754 17.8048 19.6015 17.4621 19.6106C17.1067 19.6208 16.8832 19.83 16.7617 20.1721C16.3078 20.0959 15.9031 19.9198 15.5283 19.672C15.5008 19.6538 15.4882 19.5845 15.4962 19.5447C15.6555 18.8071 15.2073 18.3808 14.468 18.6036C14.2273 18.2604 14.0817 17.8739 13.9865 17.4489C14.3442 17.325 14.5769 17.0977 14.5711 16.7124C14.5666 16.3373 14.3396 16.1145 13.9521 15.9861C14.0908 15.6269 14.2261 15.278 14.3683 14.9132C14.7637 15.0996 15.1076 15.0984 15.3816 14.7768C15.6338 14.4801 15.5971 14.1608 15.3655 13.7948C15.8263 13.5936 16.2711 13.4004 16.733 13.1992C16.8614 13.6072 17.085 13.8448 17.4724 13.8505C17.8713 13.8573 18.1017 13.6186 18.2221 13.2538C18.7219 13.3572 19.1632 13.5504 19.5701 13.8505C19.3661 14.1801 19.3443 14.4995 19.6022 14.7904C19.867 15.0882 20.1971 15.0996 20.588 14.9188C20.7279 15.2814 20.862 15.6281 21.0007 15.9872C20.6029 16.1316 20.3851 16.3703 20.4 16.7544C20.4138 17.0965 20.6167 17.3147 20.9995 17.45C20.8471 17.8342 20.6969 18.2126 20.5513 18.5786C19.624 18.5002 19.2526 18.8775 19.5025 19.6231ZM17.5171 17.9467C18.1854 17.9421 18.7184 17.4136 18.7219 16.751C18.7241 16.0804 18.1808 15.5428 17.5034 15.5462C16.8339 15.5508 16.302 16.0793 16.2986 16.7419C16.2963 17.4136 16.8408 17.9512 17.5171 17.9467Z"
                        fill="#5F6368"
                      />
                      <path
                        d="M9.90319 12.2198C10.1531 12.2198 10.4041 12.2186 10.654 12.2198C11.1687 12.222 11.4106 12.4209 11.4908 12.9267C11.7052 14.2804 11.9012 15.6363 12.1316 16.9877C12.2015 17.3957 12.1224 17.7185 11.8748 18.0458C11.4381 18.6232 11.0369 19.2256 10.6173 19.8166C10.2677 20.3087 9.74615 20.3212 9.37246 19.8496C8.88185 19.2301 8.40155 18.6016 7.90062 17.9913C7.67595 17.7174 7.59113 17.4434 7.65188 17.0854C7.88802 15.684 8.09779 14.2781 8.32017 12.8744C8.38894 12.438 8.63998 12.2277 9.08818 12.222C9.3587 12.2164 9.63152 12.2198 9.90319 12.2198ZM9.62006 13.6359C9.61089 13.6734 9.60286 13.7007 9.59828 13.7291C9.42404 14.8305 9.24866 15.9318 9.07901 17.0343C9.06869 17.1013 9.0664 17.1934 9.10193 17.2411C9.36902 17.5957 9.64757 17.9424 9.93758 18.3095C10.176 17.9719 10.4064 17.6594 10.6173 17.3366C10.6701 17.2559 10.6953 17.1332 10.6804 17.0377C10.5336 16.05 10.3732 15.0646 10.2242 14.0769C10.1496 13.5848 10.1565 13.5836 9.62006 13.6359Z"
                        fill="#5F6368"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1989_429">
                        <rect width="21" height="20.1923" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <p>Manage Administrator</p>
                </Link>
              </div>
            )) ||
              ""}
            {((adminDetails?.role == "superadmin" ||
              adminDetails?.access_permissions?.support) && (
              <div className="side-btm-space">
                <Link
                  className={
                    window.location.pathname == "/Support"
                      ? "menu-btn bar_active"
                      : "menu-btn "
                  }
                  to="/Support"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_1989_424)">
                      <path
                        d="M10.5 19.25C15.3325 19.25 19.25 15.3325 19.25 10.5C19.25 5.66751 15.3325 1.75 10.5 1.75C5.66751 1.75 1.75 5.66751 1.75 10.5C1.75 15.3325 5.66751 19.25 10.5 19.25Z"
                        stroke="#5F6368"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7.9541 7.87501C8.15982 7.29022 8.56586 6.7971 9.10031 6.483C9.63476 6.1689 10.2631 6.05408 10.8741 6.15888C11.4851 6.26369 12.0393 6.58134 12.4385 7.0556C12.8378 7.52985 13.0563 8.13009 13.0554 8.75001C13.0554 10.5 10.4304 11.375 10.4304 11.375"
                        stroke="#5F6368"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.5 14.875H10.5075"
                        stroke="#5F6368"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1989_424">
                        <rect width="21" height="21" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <p>
                    Support{" "}
                    <span className="notify-badge">
                      {window.location.pathname == "/Support"
                        ? 0
                        : supportTicket?.counter_view || 0}{" "}
                    </span>
                  </p>
                </Link>
              </div>
            )) ||
              ""}
          </div>
          <div>
            <div className="side-btm-last">
              <div>
                <Link
                  className={
                    window.location.pathname == "/ChangePassword"
                      ? "menu-btn bar_active"
                      : "menu-btn "
                  }
                  to="/ChangePassword"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="22"
                    viewBox="0 0 20 22"
                    fill="none"
                  >
                    <path
                      d="M17 10H3C1.89543 10 1 10.8954 1 12V19C1 20.1046 1.89543 21 3 21H17C18.1046 21 19 20.1046 19 19V12C19 10.8954 18.1046 10 17 10Z"
                      stroke="#5F6368"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5 10V6C5 4.67392 5.52678 3.40215 6.46447 2.46447C7.40215 1.52678 8.67392 1 10 1C11.3261 1 12.5979 1.52678 13.5355 2.46447C14.4732 3.40215 15 4.67392 15 6V10"
                      stroke="#5F6368"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <p>Change Password</p>
                </Link>
              </div>
              <div>
                <Link
                  className={
                    window.location.pathname == "/"
                      ? "menu-btn bar_active"
                      : "menu-btn "
                  }
                  to="/"
                  onClick={() => {
                    sessionStorage.clear();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                  >
                    <path
                      d="M7.875 18.375H4.375C3.91087 18.375 3.46575 18.1906 3.13756 17.8624C2.80937 17.5342 2.625 17.0891 2.625 16.625V4.375C2.625 3.91087 2.80937 3.46575 3.13756 3.13756C3.46575 2.80937 3.91087 2.625 4.375 2.625H7.875"
                      stroke="#5F6368"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14 14.875L18.375 10.5L14 6.125"
                      stroke="#5F6368"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M18.375 10.5H7.875"
                      stroke="#5F6368"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <p>Logout</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
}
