import React, { useEffect, useState } from "react";
import ReactApexCharts from "react-apexcharts";
import axios from "axios";

export default function UserActivity({ initialData }) {
  const [chartOptions, setChartOptions] = useState({
    chart: {
      height: 385,
      type: "line",
      backgroundColor: "#2B3674",
    },
    tooltip: {
      enabled: true,
    },
    labels: {
      show: false,
    },
    colors: ["#7F00FF", "#2B3674", "#ADD8E6"],
    series: [],
    xaxis: {
      categories: ["0", "07", "14", "20", "28", "30 "],
    },
  });

  useEffect(() => {
    if (initialData) {
      const data30Days = initialData?.data30Days?.graphData;
      const categories = ["0", "07", "14", "20", "28", "30 "];
      const processData = (data) => {
        const result = categories?.map(category => {
          const foundData = data?.find(item => item?.date?.split('-')[2] === category?.trim());
          return foundData ? foundData?.activeUsers : 0;
        });
        return result;
      };
      const series30Days = processData(data30Days);
      const data7Days = initialData?.data7Days?.graphData;
      const data1Day = initialData?.data1Day?.graphData;

      const series7Days = processData(data7Days);
      const series1Day = processData(data1Day);

      console.log("series30Days", series30Days)
      console.log("series7Days", series7Days)
      console.log("series1Day", series1Day)

      setChartOptions({
        ...chartOptions,
        series: [
          {
            name: "30 days",
            data: series30Days,
          },
          {
            name: "7 days",
            data: series7Days,
          },
          {
            name: "1 day",
            data: series1Day,
          },
        ],
        xaxis: {
          categories: categories,
        },
      });
    }
  }, [initialData]);

  return (
    <div>
      <ReactApexCharts
        options={chartOptions}
        series={chartOptions.series}
        type="line"
        height={385}
      />
    </div>
  );
}
