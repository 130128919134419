import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Rating } from "react-simple-star-rating";
import { useDispatch, useSelector } from "react-redux";
import { getReviewById, updateReview } from "../redux/actions/adminActions";
import moment from "moment";
import toast from "react-hot-toast";
export default function ReviewDetail() {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const { reviewDetails } = useSelector((state) => ({
    reviewDetails: state.reviewSlice.reviewDetails
  }));


  useEffect(() => {
    dispatch(getReviewById(params.id));
  }, [])

  const handleReviewUpdate = (type) =>{
     dispatch(updateReview({id: reviewDetails?._id,status: type})).then((data)=>{
      console.log(data);
      if(data?.payload?.success){
        navigate(`/allreviews`);
        toast.success(data?.payload?.message)
      }
     })
  }

  console.log(reviewDetails, "userDetails");
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Review Details</h2>
          </div>
          <div></div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={12}>
              <div className="product-tab-left">
                <Link to="/allreviews">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Container>
        <Row className="justify-content-center">
          <Col lg={8}>
            <div className="review-detail">
              <div className="text-center">
                <h2>{reviewDetails?.name || "N/A"}</h2>
                <p>{reviewDetails?.address || "N/A"}</p>
                <Rating initialValue={reviewDetails?.rating} />
              </div>
              <div className="detail-text">
                <p>{reviewDetails?.title}</p>
              </div>

              <div className="detail-inner">
                <h2>Treatment Received</h2>
                <ul>
                  {
                    reviewDetails?.treatments?.map((item)=>{
                      return (
                        <>
                         <li>{item}</li>
                        </>
                      )
                    })
                  }
                 
                </ul>
              </div>

              <div className="details-inner">
                <Row>
                  <Col lg={6}>
                    <div className="review-data">
                      <h3>Date of Experience</h3>
                      <p>{moment(reviewDetails?.date_experience).format("DD-MM-YYYY") ||
                        "N/A"}</p>
                      
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="review-data">
                      <h3>Would you recommend us to a friend?</h3>
                      <p>{reviewDetails?.suggest_a_frnd ? 'Yes' : 'No'}</p>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="review-data mt-4">
                      <h3>Value for Money?</h3>
                      <p>{reviewDetails?.value_for_money ? 'Yes' : 'No'}</p>
                    </div>
                  </Col>
                </Row>
                <hr />
              </div>
              <div className="details-inner">
                <h2>Description</h2>
                <p style={{whiteSpace:"pre-wrap"}}>
                {reviewDetails?.description}
                </p>
              </div>
            </div>

            <div className="review-details-btns">
              <button onClick={()=>handleReviewUpdate(1)} className="account-btn">Approve</button>
              <button onClick={()=>handleReviewUpdate(2)} className="account-btn-delete">Reject</button>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
