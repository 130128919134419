import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import RichTextEditor from "../Components/Layout/RichTextEditor/RichTextEditor";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import {
  createBlog,
  getBlogById,
  updateBlog,
} from "../redux/actions/adminActions";
import toast from "react-hot-toast";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function AddBlog() {
  const BASE_URL = process.env.REACT_APP_HOST_NAME;
  let query = useQuery();
  let queryId = query.get("id");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { blogDetails } = useSelector((state) => ({
    blogDetails: state.blogSlice.blogDetails,
  }));
  const [prevImg, setPrevImg] = useState([]);
  const [blogDetail, setBlogDetail] = useState();

  useEffect(() => {
    if (queryId) {
      dispatch(getBlogById(queryId)).then((data) => {
        setBlogDetail(data?.payload?.data);
      });
    }
  }, [queryId]);

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <h2>{blogDetail ? "Update Blog" : "Add Blog"} </h2>

          <div>
            <hr />
          </div>
        </div>
      </div>
      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/allblogs">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Container fluid className="height-set px-4">
        <Formik
          enableReinitialize={true}
          initialValues={{
            title: blogDetail?.title || "",
            image: blogDetail?.image || "",
            blog_content: blogDetail?.blog_content || "",
            status: blogDetail?.status || "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.title) {
              errors.title = "Required*";
            }
            if (!values.image) {
              errors.image = "Required*";
            }
            if (!values.blog_content) {
              errors.blog_content = "Required*";
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            console.log(values, "values addBlog");
            const formData = new FormData();
            for (let key in values) {
              if (key == "status") {
                if (values.status != "") {
                  formData.append("status", values.status);
                } else {
                  formData.append("status", 1);
                }
              } else {
                formData.append(key, values[key]);
              }
            }
            if (blogDetail) {
              formData.append(`id`, blogDetail?._id);
              dispatch(updateBlog(formData)).then((data) => {
                if (data?.payload?.success) {
                  toast.success(data?.payload?.message);
                  navigate(`/allblogs`);
                }
              });
            } else {
              dispatch(createBlog(formData)).then((data) => {
                if (data?.payload?.success) {
                  navigate(`/allblogs`);
                }
              });
            }

            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col lg={8}>
                  <div className="customer-form-new mb-4">
                    <div className="customer-form-inner">
                      <Row>
                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Type Here"
                              name="title"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.title}
                            />
                            <span className="formik-errors">
                              {errors.title && touched.title && errors.title}
                            </span>
                          </Form.Group>
                        </Col>

                        <Col lg={12}>
                          <Form.Group className="mb-3 image-upload-main">
                            <Form.Label>Image</Form.Label>
                            <div className="input-image-show">
                              <Form.Control
                                name="image"
                                type="file"
                                multiple
                                placeholder="Upload Image"
                                onChange={(e) => {
                                  setFieldValue("image", e.target.files[0]);
                                  setPrevImg(
                                    URL.createObjectURL(e.target.files[0])
                                  );
                                }}
                              />

                              {prevImg?.length ? (
                                <img className="fit_img" src={prevImg} />
                              ) : blogDetail?.image ? (
                                <img
                                  className="fit_img"
                                  src={BASE_URL + blogDetail?.image}
                                />
                              ) : (
                                <p>Upload Image</p>
                              )}
                            </div>
                            <span className="formik-errors">
                              {errors.image && touched.image && errors.image}
                            </span>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="text-editor">
                    <div className="text-heading">
                      <h3>CONTENT</h3>
                    </div>
                    <RichTextEditor
                      blogDetail={blogDetail}
                      givenContent={values.blog_content}
                      setFieldValue={setFieldValue}
                    />
                    <span className="formik-errors">
                      {errors.blog_content &&
                        touched.blog_content &&
                        errors.blog_content}
                    </span>
                    {/* <button onClick={log}>Log editor content</button> */}
                    <Col lg={8} className="d-flex justify-content-center mt-2">
                      {" "}
                      <div className="cmn-btn mt-4 mb-4">
                        <button type="submit">
                          {blogDetail ? "Update" : "Add"} Blog
                        </button>
                      </div>
                    </Col>
                  </div>
                </Col>
                <Col lg={4}>
                  {" "}
                  <div className="product-overview-right">
                    <div className="product-overview-box">
                      <h2>Publish Status</h2>
                      <Form.Select
                        aria-label="Default select example"
                        name="status"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.status}
                      >
                        <option value="0">Publish</option>
                        <option value="1">Draft</option>
                      </Form.Select>
                      {/* <div className="cmn-btn mt-4 d-flex justify-content-center">
                                <Link to="#">Save</Link>
                              </div> */}
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  );
}
