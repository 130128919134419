import React, { useEffect } from "react";
import Login from "./pages/Login";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LoginOtp from "./pages/LoginOtp";
import ForgotPassword from "./pages/ForgotPassword";
import AllSalons from "./pages/AllSalons";

import Support from "./pages/Support";
import Administrators from "./pages/Administrators";

import Dashboard from "./pages/Dashboard";

import Supportchat from "./pages/SupportChat";
import CreateAdmin from "./pages/CreateAdmin";

import AllReviews from "./pages/AllReviews";
import ApprovedReviews from "./pages/ApprovedReviews";
import RejectedReviews from "./pages/RejectedReviews";

import MailingList from "./pages/Mailinglist";

import AllBrands from "./pages/AllBrands";
import CreateBrand from "./pages/CreateBrand";
import ChangePassword from "./pages/ChangePassword";

import CompletedOrders from "./pages/CompletedOrders";

import Analytics from "./pages/Analytics";
import AllSalonsTempBanned from "./pages/AllSalonsTempBanned";
import AllSalonsActive from "./pages/AllSalonsActive";
import SalonProfile from "./pages/SalonProfile";
import TicketDetail from "./pages/TicketDetail";
import ReviewDetail from "./pages/ReviewDetail";
import AllBlogs from "./pages/AllBlogs";
import AddBlog from "./pages/AddBlog";
import CreateMail from "./pages/CreateMail";
import PublishBlogs from "./pages/PublishBlogs";
import DraftBlogs from "./pages/DraftBlogs";
import PrivateRoute from "./routes/PrivateRoute";
import RichTextEditor from "./Components/Layout/RichTextEditor/RichTextEditor";
import { getAdminById, getStatics } from "./redux/actions/adminActions";
import { useDispatch } from "react-redux";
import ProfileUpdates from "./pages/ProfileUpdates";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const id = sessionStorage.getItem("id");
    if (id) {
      dispatch(getAdminById(id)).then((data) => {
        console.log(data);
      });
    }
    
  }, [])

  useEffect(() => {
    dispatch(getStatics());
  
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
        <Route path="/editor" element={<RichTextEditor />} />
          <Route path="/" element={<Login />} />
          <Route path="/Otp" element={<LoginOtp />} />
          <Route path="/Forgot-Password" element={<ForgotPassword />} />
          <Route path="*" element={<Navigate to="/" />} />

          <Route  element={<PrivateRoute />}>
            <Route path="/allsalons" element={<AllSalons />} />

            <Route path="/Support" element={<Support />} />
            <Route path="/Administrators" element={<Administrators />} />

            <Route path="/Dashboard" element={<Dashboard />} />

            <Route path="/Supportchat" element={<Supportchat />} />
            <Route path="/Create-Admin" element={<CreateAdmin />} />

            <Route path="/allreviews" element={<AllReviews />} />

            <Route path="/mailinglist" element={<MailingList />} />

            <Route path="/allbrands" element={<AllBrands />} />
            <Route path="/CreateBrand" element={<CreateBrand />} />
            <Route path="/ChangePassword" element={<ChangePassword />} />

            <Route path="/CompletedOrders" element={<CompletedOrders />} />

            <Route path="/Analytics" element={<Analytics />} />
            <Route path="/temporarybanned" element={<AllSalonsTempBanned />} />
            <Route path="/salonsactive" element={<AllSalonsActive />} />
            <Route path="/salonprofile/:id" element={<SalonProfile />} />
            <Route path="/ticketdetail/:id" element={<TicketDetail />} />

            <Route path="/approvedreviews" element={<ApprovedReviews />} />
            <Route path="/rejectedreviews" element={<RejectedReviews />} />
            <Route path="/reviewdetail/:id" element={<ReviewDetail />} />
            <Route path="/allblogs" element={<AllBlogs />} />
            <Route path="/addblog" element={<AddBlog />} />
            <Route path="/createmail" element={<CreateMail />} />

            <Route path="/publishblogs" element={<PublishBlogs />} />
            <Route path="/draftblogs" element={<DraftBlogs />} />
            <Route path="/profileUpdates" element={<ProfileUpdates />} />

          </Route>

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
