import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Rating } from "react-simple-star-rating";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { deleteBlog, getBlog } from "../redux/actions/adminActions";
import Pagination from "../Components/Layout/elements/Pagination";
import moment from "moment";
export default function AllBlogs() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const BASE_URL = process.env.REACT_APP_HOST_NAME;
  const {
    blogs,
    totalBlogs,
    totalBlogCount,
    totalPublishCount,
    totalDraftCount,
  } = useSelector((state) => ({
    blogs: state.blogSlice.blogs,
    totalBlogs: state.blogSlice.totalBlogs,
    totalBlogCount: state.blogSlice.totalBlogCount,
    totalPublishCount: state.blogSlice.totalPublishCount,
    totalDraftCount: state.blogSlice.totalDraftCount,
  }));

  const [search, setSearch] = useState("");
  const [createdDate, setCreatedDate] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);

  useEffect(() => {
    dispatch(
      getBlog({
        page: search ? 1 : page,
        limit: limit,
        search: search,
        user_type: 0,
        createdDate: createdDate,
        status: 0,
      })
    );
  }, [page, limit, search, createdDate]);

  console.log(blogs, totalBlogs);
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Published Blog</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={3} md={6}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Keyword Search.."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xxl={2} xl={3} lg={3} md={6}>
                <input
                  type="date"
                  onChange={(e) => setCreatedDate(e.target.value)}
                  max={new Date().toISOString().split("T")[0]}
                />
                {/* <Form.Select aria-label="Default select example">
                  <option>Created Date</option>
                  <option value="1">24</option>
                  <option value="2">25</option>
                  <option value="3">26</option>
                </Form.Select> */}
              </Col>
              <Col xxl={2} xl={3} lg={3} md={6}>
                {/* <Form.Select aria-label="Default select example">
                  <option>Created By</option>
                  <option value="1">user</option>
                  <option value="2">user</option>
                </Form.Select> */}
              </Col>
              <Col
                xxl={5}
                xl={3}
                lg={3}
                md={6}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <Link to="/addblog">CREATE NEW</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/allblogs">
                  ALL <span>{totalBlogCount}</span>
                </Link>
              </li>
              <li>
                <Link to="/publishblogs" className="active-tab">
                  PUBLISHED <span>{totalPublishCount}</span>
                </Link>
              </li>
              <li>
                <Link to="/draftblogs">
                  DRAFTS <span>{totalDraftCount}</span>
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                Showing {1 + (page - 1) * limit} -{" "}
                {blogs?.length + (page - 1) * limit} of {totalBlogs} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  onChange={(e) => setLimit(e.target.value)}
                  aria-label="Default select example"
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Created</th>
                <th>Created By</th>
                <th>Blog Title </th>
                <th>Status</th>
                <th>Last Edited</th>
                <th>Last Edited By</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {blogs?.map((item, i) => {
                return (
                  <>
                    <tr>
                      <td>{i + 1 + (page - 1) * limit}</td>
                      <td>
                        {moment(item?.createdAt).format("DD/MM/YYYY h:mm") ||
                          "N/A"}
                      </td>
                      <td>N/A</td>
                      <td className="desc">{item?.title}</td>
                      <td>{item?.status ? "Draft" : "Published"}</td>
                      <td>
                        {moment(item?.updatedAt).format("DD/MM/YYYY h:mm") ||
                          "N/A"}
                      </td>
                      <td>N/A</td>

                      <td>
                        <Button
                          onClick={() => navigate(`/addblog?id=${item?.blog_slug}`)}
                          className="account-btn"
                        >
                          Edit Access
                        </Button>{" "}
                        <Button
                          onClick={() => dispatch(deleteBlog(item?._id))}
                          className="account-btn-delete"
                        >
                          DELETE
                        </Button>{" "}
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>
          <Pagination
            class="next-btn-new"
            totalStuff={totalBlogs}
            limit={limit}
            setPage={setPage}
          />
          {/* <div className="progress-line">Under Progress</div> */}
        </div>
      </Container>

      {/* <div className="next-btn-fix">
        <Link to="#">Next</Link>
      </div> */}
    </Layout>
  );
}
