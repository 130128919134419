export const permissionObj = [
    {label:"VIEW USER SENSITIVE DATA", key: 'view_user_sensitive_data', },
    {label:"EDIT DATA", key: 'edit_data', },
    {label:"DASHBOARD", key: 'dashboard', },
    {label:"Analytics", key: 'analytics', },
    {label:"Manage Salons", key: 'manage_salons', },
    {label:"Manage Reviews", key: 'manage_reviews', },
    {label:"Blogs", key: 'blogs', },
    {label:"SUPPORT", key: 'support', },
    {label:"Mailing List", key: 'mailing_list', },
    {label:"MANAGE ADMINISTRATION", key: 'manage_admins', },
    {label:"Manage Brands", key: 'manage_brands', },
  ]