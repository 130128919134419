import { createSlice } from "@reduxjs/toolkit";
import {
  
  editUserDetails,
  getAllUsers,
  getUserDetails,
  getAllSalon,
  tempBannedAccount,
  tempBannedAccountCustomer,
  getStatics,
  getAllProfileUpdates,
  profileUpdates,
  fetchOpeningHours,
} from "../actions/adminActions";
import { toast } from "react-hot-toast";

const initialState = {
  users: [],
  totalUsers: null,
  userDetails: null,
  totalAllUsers:null,
  allUsers:[],
  statics:null,
  profileUpdatesArr:[],
  profileUpdatesCount:null,
  opening_hours:{},
};

export const userMgmtSlice = createSlice({
  name: "userMgmtSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllSalon.fulfilled, (state, { payload }) => {
        console.log(payload,"payload");
        if (payload?.success) {
          state.users = payload.data.users;
          state.totalUsers = payload.data.totalUsers;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getUserDetails.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.userDetails = payload.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(editUserDetails.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.userDetails = state.payload?.data;
          toast.success(payload?.message);
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getAllUsers.fulfilled, (state, { payload }) => {
        console.log(payload,"userMgmtSlice");
        if (payload?.success) {
          state.allUsers = payload.data.user;
          state.totalAllUsers = payload.data.totalUser;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(tempBannedAccount.fulfilled, (state, { payload }) => {
        if (payload?.success) {

          state.allUsers = state.allUsers?.map((item)=>{
            if(item?._id == payload?.data?._id){
              return payload?.data
            }
            else{
              return item
            }
          });
          //toast.success(payload?.message);
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(tempBannedAccountCustomer.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          
          state.users = state.users?.map((item)=>{
            if(item?._id == payload?.data?._id){
              return payload?.data
            }
            else{
              return item
            }
          });
          toast.success(payload?.message);
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getStatics.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.statics = payload?.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getAllProfileUpdates.fulfilled, (state, { payload }) => {
        console.log(payload,"payload");
        if (payload?.success) {
          state.profileUpdatesArr = payload.data.service;
          state.profileUpdatesCount = payload.data.totalServices;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(profileUpdates.fulfilled, (state, { payload }) => {
        console.log(payload,"payload");
        if (payload?.success) {
          state.profileUpdatesArr = state.profileUpdatesArr?.filter((item)=> item?._id != payload?.data?._id);
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(fetchOpeningHours.fulfilled, (state, { payload }) => {
        state.opening_hours = payload?.data;
      })
  },
});

export default userMgmtSlice.reducer;
