import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import { deleteAdmin, getAllAdmin, getAdminById, updateAdmin, getAnalyticsData, getEventCounts, getAnalyticsUsersData, getAnalyticsgraphData } from "../actions/adminActions";

const initialState = {
  admins: [],
  totalAdmins: null,
  adminDetails: null,
  graphAnlyticsData: [],
  userGraphActiveData: [],
  eventCounts: null,
  analyticsUsersData: null,
  yourProperty: null,
};

export const adminSlice = createSlice({
  name: "adminSlice",
  initialState,
  reducers: {
    setYourProperty(state, action) {
      state.yourProperty = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllAdmin.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.admins = payload.data.admins;
          state.totalAdmins = payload.data.totalAdmins;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getAdminById.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.adminDetails = payload.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(deleteAdmin.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          console.log(payload, "payload");
          state.admins = state.admins?.filter((item) => item?._id != payload?.data?._id);
          // toast.success(payload?.message);
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getAnalyticsData.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.graphAnlyticsData = payload.data;
        }
      })
      .addCase(getAnalyticsgraphData.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.userGraphActiveData = payload.data;
        }
      })
      .addCase(getEventCounts.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.eventCounts = payload.data;
        }
      })
      .addCase(getAnalyticsUsersData.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.analyticsUsersData = payload.data;
        }
      })



  },
});

export const { setYourProperty } = adminSlice.actions;
export default adminSlice.reducer;
